(function (angular) {
    var STATE_NAME = 'app.account';

    angular.module('mobilezuz').config(['$stateProvider', '$urlRouterProvider', 'PAGE_ACCESS', function ($stateProvider, $urlRouterProvider, PAGE_ACCESS) {
        $stateProvider.state(STATE_NAME, {
            url: '/account?addressDetails',
            data: {
                stateAccess: PAGE_ACCESS.LOGGED_IN,
                bodyClass: 'account',
                metaTags: {
                    title: 'Account'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/account.html',
                    controller: 'AccountCtrl as accountCtrl',
                    resolve: {
                        userData: ['User', function (User) {
                            return User.getUserSettings();
                        }],
                        isEnabledAddressSettings: ['AddressService', function (AddressService) {
                            return AddressService.isEnabledAddressSetting().then(function (res) {
                                return res.isEnabled;
                            })
                        }],
                        zipCodeLimitLength:['Config', function (config) {
                            var _US_ZIP_CODE_LENGTH = 5;
                            return config.retailer.currencyCode === 'USD' ? _US_ZIP_CODE_LENGTH : undefined;
                        }]
                    }
                }
            }
        });
        $urlRouterProvider.when('/user-edit', '/account');
    }]).controller('AccountCtrl', [
        '$scope', '$rootScope', '$q', '$sce', '$state', '$filter', 'mDesign', 'Config', 'User', 'Api', 'Retailer',
        'SeniorCitizenVerificationDialog', 'Util', 'PaymentsService', 'userData', 'ChooseAreaDialog',
        'CREDIT_CARD_MODES', 'SP_SERVICES', 'SP_PAYMENTS', 'CITY_TYPES', 'PHONE_TYPES', 'ORGANIZATION_TYPES', 'AccountDeletionDialogSrv', '$timeout',
        'GOOGLE_AUTO_COMPLETE_TYPES', 'spGoogleMapsUtil',
        'isEnabledAddressSettings', 'FILTER_ADDRESS_MODE', 'AddressService', 'GOOGLE_MAP_ADDRESS_TYPES', 'GMap', '$window', 'zipCodeLimitLength',
        function ($scope, $rootScope, $q, $sce, $state, $filter, mDesign, Config, User, Api, Retailer,
            SeniorCitizenVerificationDialog, Util, PaymentsService, userData, ChooseAreaDialog,
            CREDIT_CARD_MODES, SP_SERVICES, SP_PAYMENTS, CITY_TYPES, PHONE_TYPES, ORGANIZATION_TYPES, AccountDeletionDialogSrv, $timeout,
            GOOGLE_AUTO_COMPLETE_TYPES, spGoogleMapsUtil,
            isEnabledAddressSettings, FILTER_ADDRESS_MODE, AddressService, GOOGLE_MAP_ADDRESS_TYPES, GMap, $window, zipCodeLimitLength
        ) {
            var accountCtrl = this,
                _translate = $filter('translate'),
                userLoginData = User.getUserLoginData(),
                _listeners = [],
                _originUserAddress;
            var _addressComponents = ['city', 'text1', 'zipCode'];
            var _ADDRESS_TYPE_MAP = {
                city: 'city',
                text1: 'address',
                zipCode: 'zipCode'
            };
            var _wasText1HouseNumberShown = false;
            var _houseNumberRegExp = new RegExp(/^[0-9]+$/);

            var _addressFormElementIds = {
                city: '#city-input',
                address: '#addressText-input',
                zipCode: '#zip-input',
                entrance: '#entrance-input',
                apartment: '#apartment-input',
                buildingCode: '#buildingCode-input',
                state: '#state-input',
                comments: '#addressText2-input',
                street: '#street-input',
                houseNumber: '#house-number-input',
                floor: '#floor-input',
            }

            var _addressFormFields = {
                city: 'city',
                address: 'address',
                zipCode: 'zipCode',
                entrance: 'entrance',
                apartment: 'apartment',
                buildingCode: 'buildingCode',
                state: 'state',
                comments: 'comments',
                street: 'street',
                houseNumber: 'houseNumber',
                floor: 'floor',
            }
            var _DEFAULT_ERROR_MESSAGE = {
                city: 'Sorry, Your city was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                address: 'Sorry, Your address was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                zipCode: 'Sorry, Your zip code was not found. Please select an address from auto-complete suggestions or pin your address using the Google Maps option.'
            }
            var _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN = {
                city: 'autocomplete_city_not_found_non_droppin',
                address: 'autocomplete_address_not_found_non_droppin',
                zipCode: 'autocomplete_zip_code_not_found_non_droppin'
            }

            var _addressVerificationText = Config.retailer.settings.addressVerificationText ? JSON.parse(Config.retailer.settings.addressVerificationText) : {};
            var _addressVerificationTextDroppinOff = JSON.parse(Config.retailer.settings.addressVerificationTextDroppinOff || '{}');
            var _currentLanguage = Config.language.culture;

            accountCtrl.isRunAddressAutoComplete = true;

            _listeners.push(function () {
                accountCtrl.userAdress = _originUserAddress;
                accountCtrl.user.addresses[0] = accountCtrl.userAdress;
                _originUserAddress = angular.copy(_originUserAddress);
            });

            $rootScope.title = 'Account';

            accountCtrl.user = userData;
            _setUserPoints();
            accountCtrl.zipCodeLimitLength = zipCodeLimitLength;
            accountCtrl.isSeniorCitizen = userData.isSeniorCitizen;
            accountCtrl.title = 'Account';
            accountCtrl.isValidFloor = true;
            accountCtrl.userAdress = {};
            accountCtrl.customValidatedPhoneNumbers = {};
            accountCtrl.userAdressForm = {};
            accountCtrl.backupAddress = {};
            accountCtrl.droppinDisableFields = {
                city: true,
                text1: true,
                zipCode: true,
                state: true
            }
            accountCtrl.changePass = {
                email: userLoginData.email
            };
            accountCtrl.userSettings = {
                email: userLoginData.email,
                friendlyName: ''
            };
            accountCtrl.isExternalRegistration = userData.isExternalRegistration;
            accountCtrl.updateUserIframeURL = Config.retailer.externalLoginSettings && Config.retailer.externalLoginSettings.isActive ? $sce.trustAsResourceUrl(Config.retailer.externalLoginSettings.updateUserIframeURL) : '';
            accountCtrl.genderEnum = { male: 1, female: 2, prefer_not_to_say: 3 };
            accountCtrl.logout = User.logout;
            accountCtrl.disableAddCardButton = accountCtrl.user.organization && accountCtrl.user.organization.id && accountCtrl.user.organization.organizationType !== ORGANIZATION_TYPES.NO_OBLIGO;
            accountCtrl.compiledUserAddress = Util.compiledUserAddress();
            // user.getUserSettings(true).then(function (userSettings) {
            if (accountCtrl.user.gender !== null) {
                accountCtrl.userSettings.gender = accountCtrl.user.gender.toString();
            }
            var phones = {
                1: { phoneNumber: '', typeVal: PHONE_TYPES.HOME },
                2: { phoneNumber: '', typeVal: PHONE_TYPES.WORK },
                3: { phoneNumber: '', typeVal: PHONE_TYPES.MOBILE },
                4: { phoneNumber: '', typeVal: PHONE_TYPES.VERIFY }
            };
            angular.forEach(accountCtrl.user.phones, function (phone) {
                phones[phone.typeVal] = phone;
                compileCustomValidatedPhone(phone)
            });

            accountCtrl.userSettings.phones = phones;
            //(userSettings.dateOfBirth)?  userSettings.dateOfBirth = new Date(userSettings.dateOfBirth) : new Date(1990, 1, 1);
            //_.extend(accountCtrl.userSettings, userSettings);
            accountCtrl.userSettings.dateOfBirth = new Date(accountCtrl.user.dateOfBirth);
            accountCtrl.userAdress = accountCtrl.user.addresses[0];

            // Backup address
            angular.extend(accountCtrl.backupAddress, accountCtrl.userAdress);

            _originUserAddress = angular.copy(accountCtrl.userAdress);
            accountCtrl.userSettings.friendlyName = accountCtrl.user.firstName + ' ' + accountCtrl.user.lastName;
            accountCtrl.userSettings.firstName = accountCtrl.user.firstName;
            accountCtrl.userSettings.lastName = accountCtrl.user.lastName;
            accountCtrl.userSettings.allowSendPromotions = accountCtrl.user.allowSendPromotions;
            accountCtrl.customPhoneRegExp = new RegExp(/^\(?\+?(\d[0-9\)\(-]*)$/);
            accountCtrl.phoneRegExp = new RegExp(/^\(?\+?(\d[\s,\-,(,),,,]*){7,15}$/);
            accountCtrl.customPhoneValidation = Config.retailer.customPhoneValidation && Config.retailer.customPhoneValidation.isActive;
            accountCtrl.phoneValidationSubmitted = false;
            accountCtrl.homePhone = accountCtrl.userSettings.phones[1].customPhoneNumber;
            accountCtrl.workPhone = accountCtrl.userSettings.phones[2].customPhoneNumber;
            accountCtrl.mobilePhone = accountCtrl.userSettings.phones[3].customPhoneNumber;
            accountCtrl.editPhonesMode = {};
            accountCtrl.countryNames = Util.getCountryAutocompleteOptions();

            // });
            if (accountCtrl.customPhoneValidation) {
                accountCtrl.phoneRegExp = new RegExp(/^\(?\+?(\d[0-9]*)$/);

                angular.forEach(accountCtrl.userSettings.phones, function (phone) {
                    if (Config.retailer.customPhoneValidation.areaCodes && Config.retailer.customPhoneValidation.areaCodes.length === 1) {
                        phone.areaCode = Config.retailer.customPhoneValidation.areaCodes[0]
                    }

                    if (Config.retailer.customPhoneValidation.countryCodes && Config.retailer.customPhoneValidation.countryCodes.length === 1) {
                        phone.countryCode = Config.retailer.customPhoneValidation.countryCodes[0]
                    }
                });
                accountCtrl.editPhonesMode = {
                    1: !accountCtrl.userSettings.phones[1].phoneNumber,
                    2: !accountCtrl.userSettings.phones[2].phoneNumber,
                    3: !accountCtrl.userSettings.phones[3].phoneNumber,
                }
            }

            accountCtrl.isEnabledAddressSettings = isEnabledAddressSettings;
            accountCtrl.isGMapWithZipCodeProvider = Config.retailer.settings.autocompleteAddressField && Config.retailer.settings.activeZipProvider && Config.retailer.settings.activeZipProvider.name === 'Get Address'

            accountCtrl.getCityAutoCompleteOptions = getCityAutoCompleteOptions;
            accountCtrl.getStreetAutoCompleteOptions = getStreetAutoCompleteOptions;
            accountCtrl.onCityChosen = onCityChosen;
            accountCtrl.addCreditCard = addCreditCard;
            accountCtrl.isSeniorCitizenChange = isSeniorCitizenChange;
            accountCtrl.toggleForm = toggleForm;
            accountCtrl.goToCouponsPage = goToCouponsPage;
            accountCtrl.goToContactUsPage = goToContactUsPage;
            accountCtrl.isPassEquals = isPassEquals;
            accountCtrl.changePassword = changePassword;
            accountCtrl.phoneNumber = phoneNumber;
            accountCtrl.saveMembershipInfo = saveMembershipInfo;
            accountCtrl.sendTicket = sendTicket;
            accountCtrl.createNewTicket = createNewTicket;
            accountCtrl.toggleTicket = toggleTicket;
            accountCtrl.removeCard = removeCard;
            accountCtrl.removePaypalAccount = removePaypalAccount;
            accountCtrl.validateCountryCode = validateCountryCode;
            accountCtrl.validateAreaCode = validateAreaCode;
            accountCtrl.phoneEditMode = phoneEditMode;
            accountCtrl.initAccountDeletionProcess = initAccountDeletionProcess;
            accountCtrl.checkForAutocompleteAddress = checkForAutocompleteAddress;
            accountCtrl.openAutocompleteAddress = openAutocompleteAddress;
            accountCtrl.filterCountryNames = filterCountryNames;

            accountCtrl.getCityAutoCompleteOptionsV2 = getCityAutoCompleteOptionsV2;
            accountCtrl.onCityChosenV2 = onCityChosenV2;
            accountCtrl.onCityBlurV2 = onCityBlurV2;
            accountCtrl.onAddressBlurV2 = onAddressBlurV2;
            accountCtrl.onAddressInputKeydownV2 = onAddressInputKeydownV2;
            accountCtrl.isDisableAddressFieldV2 = isDisableAddressFieldV2;
            accountCtrl.getAddressAutoCompleteOptionsV2 = getAddressAutoCompleteOptionsV2;
            accountCtrl.openGoogleMapDialogV2 = openGoogleMapDialogV2;
            accountCtrl.onAddressChosenV2 = onAddressChosenV2;
            accountCtrl.editDroppinAddressField = editDroppinAddressField;
            accountCtrl.onDroppinAddressFieldBlur = onDroppinAddressFieldBlur;
            accountCtrl.editDroppinAddressField = editDroppinAddressField;
            accountCtrl.isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields;
            accountCtrl.isShowAddressField = isShowAddressField;
            accountCtrl.cancelPinnedAddress = cancelPinnedAddress;
            accountCtrl.onZipCodeBlur = onZipCodeBlur;
            accountCtrl.saveAddress = saveAddress;
            accountCtrl.saveMembershipInfoV2 = saveMembershipInfoV2;
            accountCtrl.setUserAdressFormRef = setUserAdressFormRef;
            accountCtrl.getFieldErrorMsg = getFieldErrorMsg;

            Retailer.getRetailerSettings().then(function (response) {
                accountCtrl.deliveryAreaMethod = response.deliveryAreaMethod;
                if (response.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || response.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                    getCityAutoCompleteOptions().then(function (citiesList) {
                        if (citiesList) {
                            var matching = false;
                            for (var i = 0; i < citiesList.length; i++) {
                                if (citiesList[i] === accountCtrl.userAdress.city) {
                                    matching = true;
                                }
                            }
                            if (!matching) delete accountCtrl.userAdress.city;
                        }
                    });
                }

                var settings = Util.getBranchSettings(response);
                var hasCreditCardOption = (response.checkoutPaymentMethods || []).some(function (paymentMethod) {
                    return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD;
                });

                accountCtrl.withSavedCreditCards = hasCreditCardOption && settings.creditCardMode == CREDIT_CARD_MODES.REMEMBER_CARDS;
                accountCtrl.enableFloorApartment = response.enableFloorApartment;

                accountCtrl.isSeniorCitizenActive = response.isSeniorCitizenActive;
                _checkAddressFields();
                filterCountryNames();

                var isEnable = Config.retailer.settings.isAddressVerificationEnabled === 'true';
                var isUseGoogleMapApi = [SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON, SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS].includes(accountCtrl.deliveryAreaMethod);
                accountCtrl.isAddressVerificationEnabled = isEnable && isUseGoogleMapApi;
                accountCtrl.isUseGMapDeliveryMethod = isUseGoogleMapApi;


                if (
                    // is in pilot updates
                    accountCtrl.isEnabledAddressSettings &&
                    // is GOOGLE_MAPS / POLYGON
                    accountCtrl.isUseGMapDeliveryMethod
                ) {
                    // GOOGLE_MAPS / POLYGON with Zip-code provider will use old form => don't need to load form settings
                    if (accountCtrl.isGMapWithZipCodeProvider) {
                        accountCtrl.streetIsRequire = Config.retailer.settings.addressPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY_STREET_NOT_REQUIRED;
                        return;
                    }

                    accountCtrl.streetIsRequire = true
                    delete Config.retailer.settings.enableDefaultCountry

                    accountCtrl.isGoogleMapDropPinAllow = Config.retailer.settings.isGoogleMapDropPinAllow && Config.retailer.settings.isGoogleMapDropPinAllow === 'true';
                    accountCtrl.isGoogleMapDropPinValidateHouseNumAndStreet = Config.retailer.settings.isGoogleMapDropPinValidateHouseNumAndStreet && Config.retailer.settings.isGoogleMapDropPinValidateHouseNumAndStreet === 'true';

                    // already have address form settings
                    if (!_isNullOrEmpty(Config.retailer.settings.addressFormSettings) && !_isNullOrEmpty(Config.retailer.settings.enableAddressFiltering) && !_isNullOrEmpty(Config.retailer.settings.addressFiltering)) {
                        accountCtrl.addressFormSettings = JSON.parse(Config.retailer.settings.addressFormSettings);
                    
                        accountCtrl.enableAddressFiltering = Config.retailer.settings.enableAddressFiltering === 'true';
                        accountCtrl.addressFiltering = Number(Config.retailer.settings.addressFiltering);
                        accountCtrl.isAddressFilteringCityMode = accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                        accountCtrl.isAddressFilteringZipCodeMode = accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                    } else {
                        // haven't had address form settings yet
                        AddressService.getAddressFormSettings().then(function (formSettings) {
                            accountCtrl.addressFormSettings = formSettings.settings;

                            accountCtrl.enableAddressFiltering = !!formSettings.mode;
                            accountCtrl.addressFiltering = formSettings.mode;
                            accountCtrl.isAddressFilteringCityMode = accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                            accountCtrl.isAddressFilteringZipCodeMode = accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                        })
                    }

                    // pre-validate zipcode
                    onZipCodeBlur();
                    _getAddressFromMap();
                }
            });

            _getUserTickets();
            _initCreditCards();
            _initPaypalAccounts();

            setTimeout(function () {
                if (Config.retailer.externalLoginSettings && Config.retailer.externalLoginSettings.isActive) {
                    disableFormElements('user-edit');
                }
            }, 100);

            function _getUserTickets(getLastData) {
                User.getUserTickets(getLastData).then(function (tickets) {
                    angular.forEach(tickets, function (ticket) {
                        ticket.normalizedDate = normalizeTime(ticket.createdDate);
                        angular.forEach(ticket.comments, function (comment) {
                            comment.normalizedDate = normalizeTime(comment.dbDate + 'Z');
                        });
                    });

                    accountCtrl.tickets = tickets;
                    accountCtrl.noTickets = tickets.length === 0;
                });
            }

            function normalizeTime(time) {
                var date = new Date(time);
                var timeOffsetInMS = $rootScope.config.retailer.timeZoneOffset * 60000;
                var localTimeOffset = new Date().getTimezoneOffset() * 60000;
                return new Date(date.setTime((date.getTime() + timeOffsetInMS) - localTimeOffset));
            }

            /*
             Fields show/hide animation
             */
            var _opened;
            function toggleForm($event) {
                $event.preventDefault();
                $event.stopPropagation();

                var $element = angular.element($event.delegateTarget || $event.currentTarget),
                    $form = $element.next();

                if (_opened) {
                    _opened.css('display', 'none');

                    _opened = $form[0] !== _opened[0] ? $form.css('display', 'block') : null;
                } else {
                    _opened = $form.css('display', 'block');
                }
            }

            function disableFormElements(formId) {
                var form = document.getElementById(formId);
                if (form) {
                    var elements = form.getElementsByTagName('input');
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].disabled = true;
                        elements[i].style.opacity = "0.5";
                    }
                }
            }

            function goToCouponsPage($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $state.go('app.myCoupons');
            }

            function goToContactUsPage($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $state.go('app.retailer.contactUs');
            }

            function isPassEquals() {
                if (accountCtrl.changePass.newPass && accountCtrl.changePass.newPassConfirm) {
                    return accountCtrl.changePass.newPass == accountCtrl.changePass.newPassConfirm;
                }
            }

            function _successMsg() {
                mDesign.alert(_translate('Settings was successfully changed!'));
                //accountCtrl.userAdressForm.$setPristine();
                // accountCtrl.membershipForm.$setPristine();
                User.getUserSettings(true).then(function (userSettings) {
                    accountCtrl.user = userSettings;
                    _checkAddressFields();
                    angular.forEach(userSettings.phones, function (phone) {
                        _setPhoneInputs(phone.typeVal);
                        accountCtrl.editPhonesMode[phone.typeVal] = false;
                        compileCustomValidatedPhone(phone);
                    });
                });
            }

            function changePassword() {
                var data = {
                    token: User.getUserLoginData().token,
                    newPassword: accountCtrl.changePass.newPass,
                    oldPassword: accountCtrl.changePass.pass
                };

                Api.request({
                    method: 'POST',
                    url: 'retailers/:rid/users/_changePassword',
                    data: data
                }).then(function (respond) {
                    if (respond.success) {
                        _successMsg();
                    }
                });
            }

            function phoneNumber(index) {
                var placeholders = ['Home phone', 'Other phone', 'Cell phone'];

                return placeholders[index - 1];
            }

            function saveMembershipInfo() {
                accountCtrl.userAdress.entry = _getEntryValue(accountCtrl.userAdress.entry)
                var phonesCopy = angular.copy(accountCtrl.userSettings.phones)
                if (accountCtrl.customPhoneValidation) {
                    accountCtrl.editPhonesMode = {
                        1: !accountCtrl.userSettings.phones[1].phoneNumber,
                        2: !accountCtrl.userSettings.phones[2].phoneNumber,
                        3: !accountCtrl.userSettings.phones[3].phoneNumber,
                    }
                    accountCtrl.homePhone = phonesCopy[1] ? phonesCopy[1].customPhoneNumber : '';
                    accountCtrl.workPhone = phonesCopy[2] ? phonesCopy[2].customPhoneNumber : '';
                    accountCtrl.mobilePhone = phonesCopy[3] ? phonesCopy[3].customPhoneNumber : '';
                    var phoneCopyArr = Object.values(phonesCopy) || [];

                    phoneCopyArr.forEach(function (phone) {
                        if (accountCtrl.userSettings.phones && accountCtrl.userSettings.phones[phone.typeVal] && (accountCtrl.userSettings.phones[phone.typeVal].phoneNumber || accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber)) {
                            accountCtrl.userSettings.phones[phone.typeVal].phoneNumber = accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber ? accountCtrl.userSettings.phones[phone.typeVal].areaCode + accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber : '';
                            accountCtrl.userSettings.phones[phone.typeVal].customValidated = (accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber && accountCtrl.customPhoneValidation);
                            compileCustomValidatedPhone(accountCtrl.userSettings.phones[phone.typeVal]);
                        }
                        _setPhoneInputs(phone.typeVal);
                    });
                    accountCtrl.userSettings.phones = phones;
                }
                Util.cleanUserAddressObject(accountCtrl.userAdress);

                if (accountCtrl.userSettings.password !== accountCtrl.userSettings.confirmPassword) {
                    return mDesign.alert('The passwords must match');
                }

                var sendingData = {
                    firstName: accountCtrl.userSettings.firstName,
                    lastName: accountCtrl.userSettings.lastName,
                    birthDate: accountCtrl.userSettings.dateOfBirth,
                    allowSendPromotions: accountCtrl.userSettings.allowSendPromotions,
                    gender: parseFloat(accountCtrl.userSettings.gender),
                    phones: accountCtrl.userSettings.phones,
                    addresses: [accountCtrl.userAdress]
                };

                if (accountCtrl.userSettings.password) {
                    sendingData.password = accountCtrl.userSettings.password;
                }

                // can only unselect is senior citizen through here
                if (!accountCtrl.isSeniorCitizen && accountCtrl.user.isSeniorCitizen) {
                    sendingData.isSeniorCitizen = false;
                }
                accountCtrl.phoneValidationSubmitted = true;
                Api.request({
                    method: 'PATCH',
                    url: 'retailers/:rid/users/:uid',
                    data: sendingData
                }).then(function () {
                    _originUserAddress = angular.copy(accountCtrl.userAdress);
                    _successMsg();
                }).catch(function () {
                    User.getUserSettings(true).then(function (userSettings) {
                        accountCtrl.user = userSettings;
                        angular.forEach(userSettings.phones, function (phone) {
                            _setPhoneInputs(phone.typeVal);
                            accountCtrl.editPhonesMode[phone.typeVal] = false;
                            compileCustomValidatedPhone(phone);
                        });
                    });
                });
            }

            function phoneEditMode(phoneType, editMode) {
                if (accountCtrl.customPhoneValidation) {
                    if (accountCtrl.editPhonesMode[phoneType] && editMode) {
                        return;
                    }
                    accountCtrl.customValidatedPhoneNumbers[phoneType] = '';
                    accountCtrl.editPhonesMode[phoneType] = editMode;
                    if (editMode) {
                        accountCtrl.userSettings.phones[phoneType].customPhoneNumber = accountCtrl.userSettings.phones[phoneType].customPhoneNumber || '';
                        setTimeout(function () {
                            var newInputField = document.getElementById('phone-input-' + phoneType);
                            if (newInputField) {
                                newInputField.focus()
                            }
                        }, 0);
                        angular.forEach(accountCtrl.user.phones, function (phone) {
                            if (phone.typeVal !== phoneType) {
                                _setPhoneInputs(phone.typeVal);
                                accountCtrl.editPhonesMode[phone.typeVal] = false;
                                compileCustomValidatedPhone(phone);
                            }
                        })
                    } else {
                        _setPhoneInputs(phoneType);
                        compileCustomValidatedPhone(accountCtrl.userSettings.phones[phoneType])
                    }
                }
            }

            function compileCustomValidatedPhone(phone) {
                phone.customValidated = (phone.customValidated && phone.customPhoneNumber)
                if (phone.customValidated) {
                    if (phone.phoneNumber && phone.customValidated) {
                        accountCtrl.customValidatedPhoneNumbers[phone.typeVal] = [
                            phone.countryCode ? phone.countryCode.toString() : '',
                            phone.areaCode ? phone.areaCode.toString() : '',
                            phone.customPhoneNumber ? phone.customPhoneNumber.toString() : ''
                        ].join('');
                    } else if (phone.phoneNumber && phone.customPhoneNumber !== '' && !phone.customValidated) {
                        accountCtrl.customValidatedPhoneNumbers[phone.typeVal] = phone.phoneNumber
                    } else {
                        accountCtrl.customValidatedPhoneNumbers[phone.typeVal] = '';
                    }

                } else {
                    accountCtrl.customValidatedPhoneNumbers[phone.typeVal] = phone.phoneNumber;
                }
            }

            function _setPhoneInputs(phoneType) {
                switch (phoneType) {
                    case PHONE_TYPES.HOME: {
                        accountCtrl.userSettings.phones[1].customPhoneNumber = accountCtrl.homePhone;
                        break;
                    }
                    case PHONE_TYPES.WORK: {
                        accountCtrl.userSettings.phones[2].customPhoneNumber = accountCtrl.workPhone;
                        break;
                    }
                    case PHONE_TYPES.MOBILE: {
                        accountCtrl.userSettings.phones[3].customPhoneNumber = accountCtrl.mobilePhone;
                        break;
                    }
                    default:
                        return;
                }
            }

            function validateCountryCode(countryCode, phoneNumber) {
                if (phoneNumber && accountCtrl.phoneValidationSubmitted) {
                    return Config.retailer.customPhoneValidation.countryCodes.some(function (code) {
                        return code == countryCode;
                    });
                }
                return true;
            }


            function validateAreaCode(areaCode, phoneNumber) {
                if (phoneNumber && accountCtrl.phoneValidationSubmitted) {
                    return Config.retailer.customPhoneValidation.areaCodes.some(function (code) {
                        return code == areaCode;
                    });
                }
                return true;
            }

            function getCityAutoCompleteOptions() {
                if (!accountCtrl.userAdress.city) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/addresses/cities',
                    params: {
                        term: accountCtrl.userAdress.city,
                        size: 5
                    }
                }).then(function (data) {
                    accountCtrl.citiesObject = {};
                    return data.cities.map(function (city) {
                        accountCtrl.citiesObject[city.name] = city;
                        return city.name;
                    });
                });
            }

            function onCityChosen() {
                accountCtrl.streetIsRequire = (accountCtrl.citiesObject[accountCtrl.userAdress.city] && accountCtrl.citiesObject[accountCtrl.userAdress.city].type === CITY_TYPES.MULTIPLE_ZIP_CODES);
            }

            //set "None" value as null
            function _getEntryValue(entry) {
                if (entry === _translate($rootScope.HOUSE_ENTRIES[0])) {
                    return null;
                }
                return entry;
            }

            function getStreetAutoCompleteOptions() {
                if (!accountCtrl.userAdress.street) return;
                var cityId = accountCtrl.citiesObject[accountCtrl.userAdress.city] && accountCtrl.citiesObject[accountCtrl.userAdress.city].id;

                if (!cityId) {
                    return;
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/addresses/streets',
                    params: {
                        term: accountCtrl.userAdress.street,
                        cityId: cityId,
                        size: 4
                    }
                }).then(function (data) {
                    return data.streets.map(function (street) {
                        return street.name;
                    });
                });
            }

            /* Contact Us*/
            function sendTicket() {

                if (angular.isUndefined($rootScope.config.getBranchArea())) {
                    return ChooseAreaDialog.show(false);
                }

                accountCtrl.details = {};
                accountCtrl.details.branchId = Config.branch.id;
                accountCtrl.details.userId = accountCtrl.user.id;
                accountCtrl.details.name = accountCtrl.user.firstName + ' ' + accountCtrl.user.lastName;
                accountCtrl.details.email = accountCtrl.user.email;
                accountCtrl.details.phone = accountCtrl.user.phones && accountCtrl.user.phones.length ? accountCtrl.user.phones[0].phoneNumber : '';
                accountCtrl.details.message = accountCtrl.contactUs;

                if (accountCtrl.details.message) {
                    Api.request({
                        method: 'POST',
                        url: '/retailers/:rid/contactUs',
                        data: accountCtrl.details
                    }).then(function () {
                        //$scope.isSent = true;
                        $rootScope.$emit('contactUs', accountCtrl.details.email, accountCtrl.details.name);
                        accountCtrl.contactUs = '';
                        _getUserTickets(true);

                    }).catch(function (res) {
                        // Util.setServerErrorToForm(form, formElement, res);
                    });
                }
                else {
                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: true,
                        template: '<md-dialog><md-dialog-content>Please add new request</md-dialog-content><md-dialog-actions><md-button ng-click="hide()" aria-label="Close"  ng-bind="::\'Close\' | translate"></md-button></md-dialog-actions></md-dialog>',
                        controller: ['$scope', function ($scope) {
                            $scope.hide = hide;
                            function hide() {
                                mDesign.hide();
                            }
                        }]
                    })
                }
            }

            /* Tickets */
            function createNewTicket(selectedTicket) {
                mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/add-ticket.html',
                    controller: ['$scope', function ($scope) {
                        $scope.createUserTicketComment = createUserTicketComment;
                        $scope.selectedTicket = selectedTicket;
                        $scope.cancel = hide;
                        $scope.attachments = [];

                        function hide() {
                            mDesign.hide();
                        }

                        function createUserTicketComment() {
                            mDesign.hide();

                            if (!$scope.userTicketComment.comment) return;
                            $scope.userTicketComment.ticketId = $scope.selectedTicket.id;

                            User.uploadTicketAttachment($scope.userTicketComment.attachment).then(function (data) {
                                if (data) {
                                    $scope.userTicketComment.attachments = [data.url.fullUrl];
                                }
                                return User.createUserTicketComment($scope.userTicketComment);
                            }).then(function (data) {
                                if (!data.success) {
                                    return;
                                }

                                $scope.userTicketComment.createdDate = new Date();
                                $scope.userTicketComment.commentById = accountCtrl.user.id;
                                $scope.userTicketComment.local = true;
                                $scope.userTicketComment.normalizedDate = $scope.userTicketComment.createdDate;
                                $scope.selectedTicket.comments.push($scope.userTicketComment);
                                $scope.userTicketComment = {};
                                if ($scope.attachments.length > 0) {
                                    $scope.selectedTicket.attachments = $scope.attachments;
                                    $scope.attachments = [];
                                }
                            });
                        }
                    }]
                });
            }

            function toggleTicket(ticket) {
                angular.forEach(accountCtrl.tickets, function (ticketItem) {
                    if (ticket == ticketItem) return;
                    ticketItem.ticketInfo = false;
                });

                ticket.ticketInfo = !ticket.ticketInfo;
            }

            /* Credit Card */
            function _initCreditCards() {
                accountCtrl.creditCards = accountCtrl.user.creditCards || [];
                var defaultCard = accountCtrl.user.creditCards ? angular.filterCollection(accountCtrl.user.creditCards, function (x) {
                    return x.isDefault;
                }) : null;
                accountCtrl.creditCard = defaultCard && defaultCard.length ? defaultCard[0] : (accountCtrl.user.creditCards && accountCtrl.user.creditCards.length ? accountCtrl.user.creditCards[0] : null);
            }

            /* PayPal */
            function _initPaypalAccounts() {
                accountCtrl.paypalAccounts = accountCtrl.user.paypalAccounts || [];
                var defaultPaypalAccount = accountCtrl.user.paypalAccounts ? angular.filterCollection(accountCtrl.user.paypalAccounts, function (x) {
                    return x.isDefault;
                }) : null;
                accountCtrl.paypalAccount = defaultPaypalAccount && defaultPaypalAccount.length ? defaultPaypalAccount[0]
                    : (accountCtrl.user.paypalAccount && accountCtrl.user.paypalAccount.length ? accountCtrl.user.paypalAccount[0] : null);
            }

            function removeCard(selectedCard) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/remove-credit-card.html',
                    controller: ['$scope', function ($scope) {
                        $scope.selectedCard = selectedCard;
                        $scope.delete = deleteCardInfo;
                        $scope.cancel = cancel;

                        function cancel() {
                            mDesign.hide();
                        }

                        function deleteCardInfo() {
                            mDesign.hide();

                            return Api.request({
                                method: 'DELETE',
                                url: '/retailers/:rid/users/:uid/removecard',
                                data: {
                                    cardId: $scope.selectedCard.id
                                },
                                headers: {
                                    'content-type': 'application/json'
                                }
                            }).then(function () {
                                setTimeout(function () {
                                    User.getUserSettings(true)
                                        .then(function (data) {
                                            accountCtrl.user = data;
                                            _initCreditCards();
                                        })
                                }, 1000);
                                $scope.$applyAsync();
                            });
                        }
                    }]
                });
            }

            function addCreditCard() {
                if (Config.retailer.settings.useDeliveryAddressAsBilling && accountCtrl.compiledUserAddress) {
                    return mDesign.dialog({
                        templateUrl: 'views/templates/use-delivery-address-as-billing.html',
                        controller: ['$scope', function ($dialogScope) {
                            var dialogCtrl = this;
                            dialogCtrl.sendUserAddress = false;
                            var userAddress = accountCtrl.user.addresses && accountCtrl.user.addresses[0];
                            var userCountryName = userAddress && userAddress.country;
                            if (userCountryName) {
                                userAddress.countryCode = Util.getCountryCode(userCountryName);
                            }
                            dialogCtrl.compiledUserAddress = Util.compiledUserAddress(_originUserAddress);
                            dialogCtrl.cancel = cancel;
                            $dialogScope.continue = function () {
                                mDesign.hide();
                                return PaymentsService.addCreditCard({
                                    sendUserAddress: dialogCtrl.sendUserAddress,
                                    address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                    city: userAddress.city ? userAddress.city : '',
                                    country: userAddress.country ? userAddress.country : '',
                                    countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                }).then(function () {
                                    setTimeout(function () {
                                        User.getUserSettings(true)
                                            .then(function (data) {
                                                accountCtrl.user = data;
                                                _initCreditCards();
                                            })
                                    }, 1000);
                                });
                            };

                            function cancel() {
                                mDesign.hide();
                            }
                        }],
                        controllerAs: 'dialogCtrl',
                        styleClass: 'clear-cart-popup'
                    });
                } else {
                    return PaymentsService.addCreditCard().then(function () {
                        setTimeout(function () {
                            User.getUserSettings(true)
                                .then(function (data) {
                                    accountCtrl.user = data;
                                    _initCreditCards();
                                })
                        }, 1000);
                    });
                }
            }

            function removePaypalAccount(selected) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/remove-paypal-account.html',
                    controller: ['$scope', function ($scope) {
                        $scope.selectedAccount = selected;
                        $scope.delete = deletePaypalAccount;
                        $scope.cancel = cancel;

                        function cancel() {
                            mDesign.hide();
                        }

                        function deletePaypalAccount() {
                            mDesign.hide();

                            return Api.request({
                                method: 'DELETE',
                                url: '/retailers/:rid/users/:uid/removepaypalaccount',
                                data: {
                                    accountId: $scope.selectedAccount.id
                                },
                                headers: {
                                    'content-type': 'application/json'
                                }
                            }).then(function () {
                                return User.getUserSettings(true);
                            }).then(function (data) {
                                accountCtrl.user = data;
                                _initPaypalAccounts();
                                $scope.$applyAsync();
                            });
                        }
                    }]
                });
            }

            function isSeniorCitizenChange() {
                if (!accountCtrl.isSeniorCitizen || accountCtrl.user.isSeniorCitizen) {
                    return;
                }

                return SeniorCitizenVerificationDialog.show().then(function () {
                    return User.getUserSettings();
                }).then(function (userData) {
                    accountCtrl.user = userData;
                    accountCtrl.isSeniorCitizen = userData.isSeniorCitizen;
                });
            }

            function initAccountDeletionProcess() {
                AccountDeletionDialogSrv.show();
            }

            function _checkAddressFields() {
                if (accountCtrl.user.addresses && accountCtrl.user.addresses.length) {
                    accountCtrl.user.hasAddressDetails = Util.checkAddressFields(accountCtrl.user.addresses[0]);
                }
            }

            function _setUserPoints() {
                if (accountCtrl.user && accountCtrl.user.loyaltyClubs && accountCtrl.user.loyaltyClubs.length > 0) {
                    var loyaltyClub = Util.findUserCashbackLoyaltyClub();
                    if (loyaltyClub) {
                        Util.getUserCashbackPoints(loyaltyClub.id).then(function (response) {
                            accountCtrl.user.points = (Number(accountCtrl.user.points) || 0) +
                                (Number(response.points) || 0);
                        });
                    }
                }
            }

            function checkForAutocompleteAddress(evt) {
                if ((evt.target.disabled || angular.element(evt.target).attr('disabled') === 'disabled' || evt.target.name === 'zip') &&
                    Config.retailer.settings.autocompleteAddressField) {
                    if (evt.target.name === 'zip') {
                        mDesign.alert(_translate('zipcode error'));
                    } else {
                        if (!Config.retailer.settings.activeZipProvider.isLockedAddress) {
                            mDesign.alert(_translate('not editable error'));
                        } else if (Config.retailer.settings.activeZipProvider.isLockedAddress) {
                            if (!accountCtrl.userAdress.city) {
                                mDesign.alert(_translate('not_editable_locked_empty error'));
                            } else {
                                mDesign.alert(_translate('not_editable_locked error'));
                            }
                        }
                    }
                }
            }

            function filterCountryNames() {
                accountCtrl.countryNames = Util.getCountryAutocompleteOptions().filter(function (country) {
                    return accountCtrl.userAdress.country && country.toLowerCase().indexOf(accountCtrl.userAdress.country.toLowerCase()) !== -1;
                });
            }

            function openAutocompleteAddress(evt) {
                evt.preventDefault();
                return mDesign.dialog({
                    templateUrl: 'views/templates/choose-address.html',
                    controller: ['$scope', function ($dialogScope) {
                        var dialogCtrl = this;
                        Util.getAddressByZipCode(accountCtrl.userAdress.zipCode).then(function (result) {
                            dialogCtrl.addresses = result;
                            dialogCtrl.errorMessage = !result || !result.length;
                        })
                        $dialogScope.continue = function () {
                            if (dialogCtrl.addresses && ((dialogCtrl.addresses.length && !dialogCtrl.address) || dialogCtrl.errorMessage)) {
                                return;
                            }

                            delete dialogCtrl.address.description;
                            accountCtrl.userAdress = Object.assign(accountCtrl.userAdress, dialogCtrl.address);
                            _checkAddressFields();
                            mDesign.hide();
                        };
                        $dialogScope.cancel = function () {
                            mDesign.hide();
                        };
                    }],
                    controllerAs: 'dialogCtrl',
                    styleClass: 'choose-address',
                    ariaLabelledby: 'choose_address_dialog_title',
                })
            }

            // TODO move to service file
            //#region new address
            function saveMembershipInfoV2() {
                var phonesCopy = angular.copy(accountCtrl.userSettings.phones);
                if (accountCtrl.customPhoneValidation) {
                    accountCtrl.editPhonesMode = {
                        1: !accountCtrl.userSettings.phones[1].phoneNumber,
                        2: !accountCtrl.userSettings.phones[2].phoneNumber,
                        3: !accountCtrl.userSettings.phones[3].phoneNumber,
                    }
                    accountCtrl.homePhone = phonesCopy[1] ? phonesCopy[1].customPhoneNumber : '';
                    accountCtrl.workPhone = phonesCopy[2] ? phonesCopy[2].customPhoneNumber : '';
                    accountCtrl.mobilePhone = phonesCopy[3] ? phonesCopy[3].customPhoneNumber : '';
                    var phoneCopyArr = Object.values(phonesCopy) || [];

                    phoneCopyArr.forEach(function (phone) {
                        if (accountCtrl.userSettings.phones && accountCtrl.userSettings.phones[phone.typeVal] && (accountCtrl.userSettings.phones[phone.typeVal].phoneNumber || accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber)) {
                            accountCtrl.userSettings.phones[phone.typeVal].phoneNumber = accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber ? accountCtrl.userSettings.phones[phone.typeVal].areaCode + accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber : '';
                            accountCtrl.userSettings.phones[phone.typeVal].customValidated = (accountCtrl.userSettings.phones[phone.typeVal].customPhoneNumber && accountCtrl.customPhoneValidation);
                            compileCustomValidatedPhone(accountCtrl.userSettings.phones[phone.typeVal]);
                        }
                        _setPhoneInputs(phone.typeVal);
                    });
                    accountCtrl.userSettings.phones = phones;
                }

                if (accountCtrl.userSettings.password !== accountCtrl.userSettings.confirmPassword) {
                    return mDesign.alert('The passwords must match');
                }

                var sendingData = {
                    firstName: accountCtrl.userSettings.firstName,
                    lastName: accountCtrl.userSettings.lastName,
                    birthDate: accountCtrl.userSettings.dateOfBirth,
                    allowSendPromotions: accountCtrl.userSettings.allowSendPromotions,
                    gender: parseFloat(accountCtrl.userSettings.gender),
                    phones: accountCtrl.userSettings.phones,
                };

                if (accountCtrl.userSettings.password) {
                    sendingData.password = accountCtrl.userSettings.password;
                }

                // can only unselect is senior citizen through here
                if (!accountCtrl.isSeniorCitizen && accountCtrl.user.isSeniorCitizen) {
                    sendingData.isSeniorCitizen = false;
                }
                accountCtrl.phoneValidationSubmitted = true;
                Api.request({
                    method: 'PATCH',
                    url: 'retailers/:rid/users/:uid',
                    data: sendingData
                }).then(function () {
                    _successMsg();
                }).catch(function () {
                    User.getUserSettings(true).then(function (userSettings) {
                        accountCtrl.user = userSettings;
                        angular.forEach(userSettings.phones, function (phone) {
                            _setPhoneInputs(phone.typeVal);
                            accountCtrl.editPhonesMode[phone.typeVal] = false;
                            compileCustomValidatedPhone(phone);
                        });
                    });
                });
            }

            function saveAddress() {
                $q.resolve(_validateAddressFields()).then(function (emptyFieldError) {
                    if (!!emptyFieldError) {
                        if (emptyFieldError && emptyFieldError.selector && emptyFieldError.selector[0]) {
                            var checkoutContainer = document.querySelector('#main');
                            if (checkoutContainer && emptyFieldError.selector[0]) {
                                angular.element(checkoutContainer).scrollTop(emptyFieldError.selector[0].offsetTop, 100);
                                emptyFieldError.selector.focus();
                            }
                        }

                        return emptyFieldError;
                    }

                    var _userAdress = angular.copy(accountCtrl.userAdress, {});

                    if (accountCtrl.isDropPinAddress) {
                        if(accountCtrl.isDropPinAddressNotDetected && accountCtrl.isGoogleMapDropPinValidateHouseNumAndStreet){
                            var text1Obj = Util.constructAdrsText1(_userAdress.countryCode, _userAdress.houseNumber, _userAdress.street, _userAdress.city);
                            _userAdress.text1 = text1Obj.value;
                        }
                        
                        _userAdress.street = '';
                        _userAdress.houseNumber = '';
                        delete _userAdress.externalPlaceId;
                    }

                    Util.cleanUserAddressObject(_userAdress);
                    Api.request({
                        method: 'PATCH',
                        url: 'retailers/:rid/users/:uid',
                        data: {
                            addresses: [_userAdress]
                        }
                    }).then(function () {
                        $window.localStorage.removeItem("mobileZuzTypedAreaText");
                        $window.localStorage.removeItem("mobileZuzTypedAddressText");
                        $window.localStorage.removeItem("newAddress");
                        _originUserAddress = angular.copy(_userAdress);
                        _successMsg();
                    }).catch(function () {
                        User.getUserSettings(true).then(function (userSettings) {
                            accountCtrl.user = userSettings;
                        });
                    });
                })
            }

            function _showAddressWarning(errorField) {
                var message;
                var addressType = _ADDRESS_TYPE_MAP[errorField];
                var addressErrorMap;

                if (accountCtrl.isGoogleMapDropPinAllow) {
                    message = _DEFAULT_ERROR_MESSAGE[addressType];
                    addressErrorMap = _addressVerificationText && _addressVerificationText[addressType];
                    if (addressErrorMap && addressErrorMap[_currentLanguage]) {
                        message = addressErrorMap[_currentLanguage];
                    }
                } else {
                    message = _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN[addressType];
                    addressErrorMap = _addressVerificationTextDroppinOff && _addressVerificationTextDroppinOff[addressType];
                    if (addressErrorMap && addressErrorMap[_currentLanguage]) {
                        message = addressErrorMap[_currentLanguage];
                    }
                }

                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/address-warning.html',
                    controller: ['$scope', function ($scope) {
                        $scope.errorMessage = _translate(message);
                        $scope.hide = mDesign.hide;
                    }]
                });
            }

            function _validateAddressFields() {
                var checkoutForm = document.querySelector('#adress-form');

                // validate base on address form settings
                var element = getInvalidFormElement(accountCtrl.addressFormSettings);
                if (element) {
                    return { selector: angular.element(checkoutForm.querySelector(element)) };
                }


                if (accountCtrl.isAddressVerificationEnabled) {
                    var suggestionFieldsIsValid = _verifyIsSuggestedValue();

                    for (var key in suggestionFieldsIsValid) {
                        if (!suggestionFieldsIsValid[key]) {
                            accountCtrl.userAdress[key] = '';
                            return _showAddressWarning(key).then(function () {
                                var _selector;
                                if (key === 'text1') { _selector = _addressFormElementIds.address };
                                if (key === 'city') { _selector = _addressFormElementIds.city };
                                if (key === 'zipCode') { _selector = _addressFormElementIds.zipCode };
                                return { selector: angular.element(checkoutForm.querySelector(_selector)) };
                            });
                        }
                    }
                }

                return $q.resolve(_validateText1HouseNumber());
            }

            function _validateText1HouseNumber() {
                var isIgnoreValidation = [
                    _wasText1HouseNumberShown,
                    !accountCtrl.userAdress.text1,
                    !accountCtrl.streetIsRequire,
                    accountCtrl.userAdress.externalPlaceId,
                    accountCtrl.userAdress.lat && accountCtrl.userAdress.lng,
                ].some(function (value) {
                    return value;
                });

                if (isIgnoreValidation) {
                    return;
                }

                var _text1 = accountCtrl.userAdress.text1;
                var splitAddress = _text1.replace(/,/g, ' ');
                splitAddress = splitAddress.replace(/\s+/g, ' ');
                splitAddress = splitAddress.split(' ');

                var hasHouseNumber = splitAddress.length >= 2 && splitAddress.some(function (word) {
                    return _houseNumberRegExp.test(word);
                });
                if (hasHouseNumber) {
                    return;
                }

                _wasText1HouseNumberShown = true;
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/address-number-warning.html',
                    controller: ['$scope', function ($scope) {
                        $scope.hide = mDesign.hide;
                    }]
                }).then(function () {
                    return { selector: angular.element(document.querySelector(_addressFormElementIds.address)) };
                });
            }


            function getInvalidFormElement(addressFormSettings) {
                var _invalidMandatoryField;
                if (!accountCtrl.enableAddressFiltering || accountCtrl.isAddressFilteringCityMode) {
                    var _invalidMandatoryField = _validateMandatoryFieldsWithOrdering(addressFormSettings, [
                        _addressFormFields.city,
                        _addressFormFields.address,
                        _addressFormFields.street,
                        _addressFormFields.houseNumber,
                        _addressFormFields.zipCode,
                        _addressFormFields.entrance,
                        _addressFormFields.floor,
                        _addressFormFields.apartment,
                        _addressFormFields.buildingCode,
                        _addressFormFields.state,
                        _addressFormFields.comments,
                    ]);
                } else if (accountCtrl.isAddressFilteringZipCodeMode) {
                    var _invalidMandatoryField = _validateMandatoryFieldsWithOrdering(addressFormSettings, [
                        _addressFormFields.zipCode,
                        _addressFormFields.address,
                        _addressFormFields.street,
                        _addressFormFields.houseNumber,
                        _addressFormFields.floor,
                        _addressFormFields.apartment,
                        _addressFormFields.state,
                        _addressFormFields.city,
                        _addressFormFields.comments,
                        _addressFormFields.entrance,
                        _addressFormFields.buildingCode,
                    ]);
                }

                if (_invalidMandatoryField) return _invalidMandatoryField;

                return;
            }

            function _validateMandatoryFieldsWithOrdering(addressFormSettings, ordering) {
                for (var i = 0; i < ordering.length; i++) {
                    var field = ordering[i];
                    switch (field) {
                        case _addressFormFields.city: {
                            var citySetting = addressFormSettings.city;
                            var city = extractAddressField('city');

                            if (_isInvalidMandatoryFieldValue(citySetting, city)) return _addressFormElementIds.city;
                            break;
                        }
                        case _addressFormFields.zipCode: {
                            var zipCodeSetting = addressFormSettings.zipCode;
                            var zipCode = extractAddressField('zipCode');

                            if (_isInvalidMandatoryFieldValue(zipCodeSetting, zipCode)) return _addressFormElementIds.zipCode;
                            break;
                        }
                        case _addressFormFields.entrance: {
                            var entranceSetting = addressFormSettings.entrance;
                            var entry = extractAddressField('entry');

                            if (_isInvalidMandatoryFieldValue(entranceSetting, entry)) return _addressFormElementIds.entrance;
                            break;
                        }
                        case _addressFormFields.apartment: {
                            var apartmentSetting = addressFormSettings.apartment;
                            var apartment = extractAddressField('apartment');

                            if (_isInvalidMandatoryFieldValue(apartmentSetting, apartment)) return _addressFormElementIds.apartment;
                            break;
                        }
                        case _addressFormFields.buildingCode: {
                            var buildingCodeSetting = addressFormSettings.buildingCode;
                            var buildingCode = extractAddressField('buildingCode');

                            if (_isInvalidMandatoryFieldValue(buildingCodeSetting, buildingCode)) return _addressFormElementIds.buildingCode;
                            break;
                        }
                        case _addressFormFields.state: {
                            var stateSetting = addressFormSettings.state;
                            var state = extractAddressField('state');

                            if (_isInvalidMandatoryFieldValue(stateSetting, state)) return _addressFormElementIds.state;
                            break;
                        }
                        case _addressFormFields.comments: {
                            var commentsSetting = addressFormSettings.comments;
                            var comment = extractAddressField('text2');

                            if (_isInvalidMandatoryFieldValue(commentsSetting, comment)) return _addressFormElementIds.comments;
                            break;
                        }
                        case _addressFormFields.address: {
                            if (isShowAddressField()) {
                                var addressSetting = addressFormSettings.address;
                                var text1 = extractAddressField('text1');

                                if (_isInvalidMandatoryFieldValue(addressSetting, text1)) return _addressFormElementIds.address;
                            }
                            break;
                        }
                        case _addressFormFields.street: {
                            var _isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields();
                            if (_isShowStreetAndHouseNumberFields) {
                                var street = extractAddressField('street');
                                if (_isNullOrEmpty(street)) return _addressFormElementIds.street;
                            }
                            break;
                        }
                        case _addressFormFields.houseNumber: {
                            var _isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields();
                            if (_isShowStreetAndHouseNumberFields) {
                                var houseNumber = accountCtrl.userAdress.houseNumber;
                                if (_isNullOrEmpty(houseNumber)) return _addressFormElementIds.houseNumber;
                            }
                            break;
                        }
                        case _addressFormFields.floor: {
                            var floorSetting = addressFormSettings.floor || {};
                            var floor = accountCtrl.userAdress.floor;
                            if (_isInvalidMandatoryFieldValue(floorSetting, floor) || (floorSetting.isEnabled && !accountCtrl.isValidFloor)) return _addressFormElementIds.floor;
                            break;
                        }
                    }
                }

                return null;
            }

            function _isInvalidMandatoryFieldValue(fieldSetting, fieldValue) {
                fieldSetting = fieldSetting || {};
                return fieldSetting.isEnabled && fieldSetting.isMandatory && _isNullOrEmpty(fieldValue);
            }

            function onCityBlurV2(event) {
                if(_isClickToSuggestion(event)) return;

                var _isCityChanged = _isLocationChanged('city');
                _handleOnLocationBlur('city');

                if (accountCtrl.isAddressVerificationEnabled && accountCtrl.isAddressFilteringCityMode && _isCityChanged) {
                    accountCtrl.suggestedAddresses = [];
                    accountCtrl.suggestedZipCodes = [];
                    accountCtrl.userAdress.text1 = '';
                    accountCtrl.userAdress.zipCode = '';

                }
            }

            function onAddressBlurV2(event) {
                if(_isClickToSuggestion(event)) return;

                var _isAddressChanged = _isLocationChanged('text1');
                if (_isAddressChanged) {
                    accountCtrl.isDropPinAddress = false;
                    accountCtrl.isDropPinAddressNotDetected = false;
                }

                var text1 = extractAddressField('text1');
                if (text1) {
                    _handleWithExistingText1(text1);
                }
            }

            function _handleWithExistingText1(text1) {
                if (!accountCtrl.isAddressVerificationEnabled) {
                    return;
                }

                var city = extractAddressField('city');
                if (!city) {
                    // Auto fill city from text1
                    var suggestion = accountCtrl.suggestedAddresses.find(function (item) {
                        return item.mainValue && item.mainValue.toLowerCase() === text1.toLowerCase();
                    });
                    if (suggestion) {
                        onAddressChosenV2({ option: suggestion });
                    }
                }

                _handleOnLocationBlur('text1');
            }

            function isExistedInSuggestions(addressType, value, suggestions) {
                var hasCoordinatesOrPlaceId = (accountCtrl.userAdress.lat && accountCtrl.userAdress.lng) || accountCtrl.userAdress.externalPlaceId;
                if (!value || hasCoordinatesOrPlaceId) {
                    return true;
                }

                if(!suggestions || !suggestions.length) return false;

                for (var idx = 0; idx < suggestions.length; idx++) {
                    var item = suggestions[idx];
                    switch (addressType) {
                        case _ADDRESS_TYPE_MAP.city: {
                            if (item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase()) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.text1: {
                            var isValid = _isAddrSuggestionValid(item.placeTypes);
                            var isExisted = item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase();
                            if (isValid && isExisted) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.zipCode: {
                            if (item.mainValue && item.mainValue.toLowerCase().replace(/\s+/g, '') === value.toLowerCase().replace(/\s+/g, '')) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }

            function _setOrInitValueForAddressField(field, value, initialValue) {
                var isDefined = angular.isDefined(accountCtrl.userAdress[field]);
                accountCtrl.userAdress[field] = isDefined ? value : initialValue;
            }

            function _isLocationChanged(field) {
                if (!accountCtrl.backupAddress || !accountCtrl.backupAddress[field] || !accountCtrl.userAdress[field]) {
                    return true;
                }

                return accountCtrl.backupAddress[field].trim().toLowerCase() !== accountCtrl.userAdress[field].trim().toLowerCase();
            }

            function _handleOnLocationBlur(emittedField) {
                // The externalPlaceId is used in setDeliveryTimes function, it's used to fetch all delivery time slots
                var isFieldChanged = _isLocationChanged(emittedField);
                if (isFieldChanged) {
                    angular.extend(accountCtrl.userAdress, {
                        externalPlaceId: null,
                        lat: null,
                        lng: null,
                    });
                    angular.extend(accountCtrl.backupAddress, accountCtrl.userAdress);
                }
                // Compare to previous address, if they are match, we will set coordinates and externalPlaceId
                var isPrevAddr = _isPreviousAddress(accountCtrl.userAdress);
                if (isPrevAddr) {
                    var _curentAddress = accountCtrl.userAdress;
                    var _hasCoordinatesOrPlaceId = _curentAddress.lat && _curentAddress.lng || _curentAddress.externalPlaceId;
                    if(accountCtrl.isAddressVerificationEnabled && _hasCoordinatesOrPlaceId) return;

                    var externalPlaceId =  _originUserAddress.externalPlaceId;
                    angular.extend(accountCtrl.userAdress, {
                        externalPlaceId: externalPlaceId,
                        lat: _originUserAddress.lat,
                        lng: _originUserAddress.lng,
                    });
                }
            }


            function getCityAutoCompleteOptionsV2() {
                var city = extractAddressField('city');
                if (!city) {
                    return $q.resolve([]);
                }

                return AddressService.getCitiesFromGoogleMapApi(city).then(function (data) {
                    accountCtrl.suggestedCities = data.cities;
                    return data.cities;
                });
            }

            function onCityChosenV2($event) {
                var cityComponent = $event && $event.option && $event.option.component;

                if ($event.option.placeId) {
                    cityComponent.placeId = $event.option.placeId;
                }

                if (cityComponent) {
                    angular.extend(accountCtrl.userAdress, cityComponent);
                }
                
                onCityBlurV2();
                return;
            }

            function onAddressInputKeydownV2() {
                if (accountCtrl.isAddressVerificationEnabled) {
                    accountCtrl.isRunAddressAutoComplete = true;
                }
            }

            function getAddressAutoCompleteOptionsV2() {
                if (!accountCtrl.isRunAddressAutoComplete) {
                    return $q.resolve([]);
                }

                var addressQuery = extractAddressField('text1');
                var _addressFilteringMode = accountCtrl.isEnabledAddressSettings && accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering ? accountCtrl.addressFiltering : null;

                // Call google api
                return AddressService.getAddressAutoCompleteOptions(addressQuery, {
                    addressFilteringMode: _addressFilteringMode,
                    placeId: accountCtrl.userAdress.placeId,
                    city: accountCtrl.userAdress.city,
                    zipCode: accountCtrl.userAdress.zipCode,
                    languageId: $rootScope.config.language.id
                }).then(function (addresses) {
                    accountCtrl.suggestedAddresses = addresses;
                    return addresses;
                });
            }

            function goToGoogleMapSelectPage(_defaultLocation) {
                $state.go("app.cart.checkout.process.map", { from: STATE_NAME, defaultLocation: _defaultLocation });
            }

            function openGoogleMapDialogV2() {
                var _defaultLocation;
                if (accountCtrl.enableAddressFiltering) {
                    switch (accountCtrl.addressFiltering) {
                        case FILTER_ADDRESS_MODE.ZIP_CODE: {
                            _defaultLocation = accountCtrl.userAdress.zipCode;
                            break;
                        }
                        case FILTER_ADDRESS_MODE.CITY: {
                            _defaultLocation = accountCtrl.userAdress.city;
                            break;
                        }
                    }
                }

                if (window.cordova) {
                    return goToGoogleMapSelectPage(_defaultLocation);
                }

                mDesign.dialog({
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/google-map-dialog.html',
                    controller: 'GoogleMapDialogCtrl as googleMapDialogCtrl',
                    locals: {
                        defaultLocation: _defaultLocation
                    }
                }).then(function (selectedAddress) {
                    _handleSelectedGoogleMapAddress(selectedAddress);
                });
            }

            function _handleSelectedGoogleMapAddress(selectedAddress) {
                if (!selectedAddress) {
                    return;
                }

                accountCtrl.isDropPinAddress = true;
                accountCtrl.isDropPinAddressNotDetected = !selectedAddress.hasHouseNumberAndRoute;

                angular.extend(accountCtrl.userAdress, {
                    text1: selectedAddress.text1,
                    city: selectedAddress.city,
                    country: selectedAddress.country,
                    countryCode: selectedAddress.countryCode,
                    zipCode: selectedAddress.zipCode,
                    state: selectedAddress.state,
                    lat: selectedAddress.lat,
                    lng: selectedAddress.lng,
                    externalPlaceId: null,
                });

                angular.extend(accountCtrl.backupAddress, accountCtrl.userAdress);

                // set form value change to show save btn
                _triggerFormValueChange();

                if (selectedAddress.zipCode) {
                    onZipCodeBlur();
                }
            }

            function onAddressChosenV2($event) {
                var addressComponent = $event && $event.option && $event.option.component;
                if (addressComponent) {
                    return AddressService.geocodeByGgMapApi({ googlePlaceId: addressComponent.externalPlaceId, languageId: $rootScope.config.language.id }).then(function (_res) {
                        var _gAddress = _res.results[0];
                        var extractedInfo = GMap.extractInfoFromAddressComponents(_gAddress.address_components);
                        var text1Obj = Util.constructAdrsText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);

                        var _address = {
                            text1: text1Obj.value,
                            city: extractedInfo.city,
                            country: extractedInfo.country,
                            countryCode: extractedInfo.countryCode,
                            zipCode: extractedInfo.zipCode,
                            state: extractedInfo.state,
                            lng: _gAddress.geometry.location.lng,
                            lat: _gAddress.geometry.location.lat,
                            externalPlaceId: _gAddress.place_id,
                        }

                        if (accountCtrl.isAddressFilteringCityMode) {
                            delete _address.city;
                        } else if (accountCtrl.isAddressFilteringZipCodeMode) {
                            delete _address.zipCode;
                        }

                        angular.extend(accountCtrl.userAdress, _address);
                        accountCtrl.isRunAddressAutoComplete = false;
                        angular.extend(accountCtrl.backupAddress, accountCtrl.userAdress);

                        // set form value change to show save btn
                        _triggerFormValueChange();
                        onAddressBlurV2();
                    });
                }
            }

            function _isPreviousAddress(currAddr) {
                var prevAddr = _originUserAddress;
                if (!prevAddr) return false;

                return _addressComponents.every(function (field) {
                    if(!prevAddr[field]) prevAddr[field] = '';
                    if(!currAddr[field]) currAddr[field] = '';

                    return prevAddr[field].trim().toLowerCase() === currAddr[field].trim().toLowerCase();
                });
            }

            function _isAddrSuggestionValid(placeTypes) {
                // The "text1" is the address detail
                var isInvalidPlaceType = [GOOGLE_MAP_ADDRESS_TYPES.ROUTE].some(function (type) {
                    return placeTypes.includes(type);
                });
                return !isInvalidPlaceType;
            }

            function extractAddressField(field) {
                return accountCtrl.userAdress && accountCtrl.userAdress[field] ? accountCtrl.userAdress[field].trim() : null;
            }

            function isDisableAddressFieldV2(filteringMode) {
                // only work when isAddressVerificationEnabled ON
                if (!accountCtrl.isAddressVerificationEnabled) return false;

                // in CITY mode address field will disable when city field is empty
                if (filteringMode === FILTER_ADDRESS_MODE.CITY && accountCtrl.isAddressFilteringCityMode && !accountCtrl.userAdress.city) return true;

                // in ZIP_CODE mode address field will disable when city field is empty
                if (filteringMode === FILTER_ADDRESS_MODE.ZIP_CODE && accountCtrl.isAddressFilteringZipCodeMode && !accountCtrl.userAdress.zipCode) return true;

                return false;
            }

            function isJustDropedNotDetectedAddress() {
                // address just droped pin - not the address from previous order
                return accountCtrl.isDropPinAddress && accountCtrl.isDropPinAddressNotDetected;
            }

            function isShowStreetAndHouseNumberFields() {
                if (!accountCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return false;

                // DROP PIN
                return isJustDropedNotDetectedAddress();
            }

            function editDroppinAddressField(elementSelector, field) {
                var _element = document.querySelector(elementSelector);
                if (_element) {
                    accountCtrl.droppinDisableFields[field] = false;
                    $timeout(function () {
                        _element.focus();
                    }, 0);
                }
            }

            function isShowAddressField() {
                var _isAddressFieldEnable = accountCtrl.addressFormSettings.address.isEnabled
                if (!_isAddressFieldEnable) return false;

                if (!accountCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return true;

                var _isJustDropedNotDetectedAddress = isJustDropedNotDetectedAddress();
                return !_isJustDropedNotDetectedAddress;
            }

            function onDroppinAddressFieldBlur() {
                accountCtrl.droppinDisableFields.city = true;
                accountCtrl.droppinDisableFields.text1 = true;
                accountCtrl.droppinDisableFields.zipCode = true;
                accountCtrl.droppinDisableFields.state = true;
            }

            function _getZipCodeFromGoogleMapApi(zipCode) {
                return AddressService.getZipCodeFromGoogleMapApi(zipCode, '', zipCode, true);
            }

            function onZipCodeBlur() {
                if (!accountCtrl.isAddressFilteringZipCodeMode) {
                    return
                }

                var _isZipCodeChanged = _isLocationChanged('zipCode');
                _handleOnLocationBlur('zipCode');

                if (accountCtrl.isAddressVerificationEnabled && _isZipCodeChanged) {
                    accountCtrl.suggestedAddresses = [];
                    accountCtrl.suggestedCities = [];
                    accountCtrl.userAdress.text1 = '';
                    accountCtrl.userAdress.city = '';
                }

                if (accountCtrl.isAddressVerificationEnabled) {
                    var zipCode = extractAddressField('zipCode');
                    if (!zipCode) {
                        return $q.resolve([]);
                    }

                    //generate another promise that will return the full cities array (no only names)
                    var zipCodesDefer = $q.defer();
                    var getRequestFunc = _getZipCodeFromGoogleMapApi;

                    return getRequestFunc(zipCode).then(function (data) {
                        accountCtrl.suggestedZipCodes = data.zipCodes;
                        var isExisted = isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, accountCtrl.suggestedZipCodes);
                        accountCtrl.isZipCodeValid = isExisted && !!accountCtrl.suggestedZipCodes.length;

                        zipCodesDefer.resolve(data.zipCodes);
                        return data.zipCodes;
                    }).catch(function (err) {
                        zipCodesDefer.reject(err);
                    });
                }
            }

            function _verifyIsSuggestedValue() {
                var city = extractAddressField('city');
                var text1 = extractAddressField('text1');
                var zipCode = extractAddressField('zipCode');

                var address = text1 && city ? text1 + ', ' + city : text1;
                var verifyAddressObj = {}

                if (accountCtrl.isDropPinAddress) return {}

                if (accountCtrl.addressFormSettings.address.isEnabled) {
                    verifyAddressObj.text1 = isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, accountCtrl.suggestedAddresses);
                }

                if (accountCtrl.addressFormSettings.city.isEnabled) {
                    verifyAddressObj.city = isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, accountCtrl.suggestedCities);
                }

                if (accountCtrl.enableAddressFiltering && accountCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE) {
                    verifyAddressObj.zipCode = isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, accountCtrl.suggestedZipCodes);
                }

                return verifyAddressObj;
            }

            function cancelPinnedAddress() {
                accountCtrl.isDropPinAddress = false;
                accountCtrl.isDropPinAddressNotDetected = false;
                accountCtrl.droppinDisableFields.city = false;
                accountCtrl.droppinDisableFields.text1 = false;
                accountCtrl.droppinDisableFields.zipCode = false;
                accountCtrl.droppinDisableFields.state = false;

                _setOrInitValueForAddressField('zipCode', '');
                _setOrInitValueForAddressField('text1', '');
                _setOrInitValueForAddressField('city', '');
                _setOrInitValueForAddressField('street', '');
                _setOrInitValueForAddressField('houseNumber', '');
                _setOrInitValueForAddressField('state', '');
            }

            function getFieldErrorMsg(field) {
                var _mgs = _translate('address_form_field_error_' + field);

                return _mgs;
            }

            // prevent case false (boolean)
            function _isNullOrEmpty(value) {
                if (value === null || value === undefined || value === '') {
                    return true;
                }

                if (typeof value === 'string' && value.trim() === '') {
                    return true;
                }

                return false;
            }

            function _triggerFormValueChange() {
                // set form value change to show save btn
                accountCtrl.userAdressForm.$setDirty();
            }

            function _isClickToSuggestion(event){
                // if click to choose suggestion, disable blur event temporarily
                var _parentTargetClickId = event && event.relatedTarget && event.relatedTarget.parentElement ? event.relatedTarget.parentElement.id : null;
                if (_parentTargetClickId && _parentTargetClickId.startsWith('sp_autocomplete_')) {
                    return true;
                }
                return false;
            }

            function setUserAdressFormRef(userAdressFormRef) {
                accountCtrl.userAdressForm = userAdressFormRef;
            }

            function _getAddressFromMap() {
                setTimeout(function () {
                    var adrsDetailsParams = $state.params.addressDetails;
                    if (adrsDetailsParams) {
                        // set form data after droppin
                        _handleSelectedGoogleMapAddress(JSON.parse(adrsDetailsParams));

                        // trigger open address tab
                        var _addressView = document.getElementById('addresses')
                        _addressView.click();
                        _addressView.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 100);
            }
            //#endregion new address

            Util.destroyListeners($scope, _listeners);
        }]);
})(angular);
