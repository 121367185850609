
(function (angular) {
    var STATE_NAME = 'app.cart.checkout.process.address';
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider) {
        $stateProvider.state(STATE_NAME, {
            url: '/address?addressDetails',
            checkoutViewOriginalIndex: 1,
            checkoutViewIndex: 1,
            views: {
                'checkoutProcess': {
                    templateUrl: 'views/checkout/process/address.html',
                    controller: 'CheckoutAddressCtrl as checkoutAddressCtrl',
                    resolve: {
                        isEnabledAddressSettings: ['AddressService', function (AddressService) {
                            return AddressService.isEnabledAddressSetting().then(function (res) {
                                return res.isEnabled;
                            })
                        }],
                        zipCodeLimitLength:['Config', function (config) {
                            var _US_ZIP_CODE_LENGTH = 5;
                            return config.retailer.currencyCode === 'USD' ? _US_ZIP_CODE_LENGTH : undefined;
                        }]
                    }
                }
            }
        });
    }]).controller('CheckoutAddressCtrl', [
        '$scope', '$rootScope', '$filter', '$q', '$timeout', 'Config', 'Api', 'User', 'ShippingTermsPopup', 'Retailer',
        'SpDeliveryAreasService', 'mDesign', 'branchArea', 'SP_SERVICES', 'CITY_TYPES', 'HOUSE_ENTRIES',
        'DELIVERY_AREA_METHODS_PRECISION', 'PHONE_TYPES', 'Util', 'DataLayer', 'Cart', 'mDesign', 'AddressService', '$state', 'GOOGLE_MAP_ADDRESS_TYPES', 'FILTER_ADDRESS_MODE', 'GMap', 'isEnabledAddressSettings', 'zipCodeLimitLength',
        function ($scope, $rootScope, $filter, $q, $timeout, Config, Api, User, ShippingTermsPopup, Retailer,
                  SpDeliveryAreasService, mDesign, branchArea, SP_SERVICES, CITY_TYPES, HOUSE_ENTRIES,
                  DELIVERY_AREA_METHODS_PRECISION, PHONE_TYPES, util, DataLayer, Cart, mDesign, AddressService, $state, GOOGLE_MAP_ADDRESS_TYPES, FILTER_ADDRESS_MODE, GMap, isEnabledAddressSettings, zipCodeLimitLength) {
            var checkoutProcessCtrl = $scope.checkoutProcessCtrl,
                checkoutAddressCtrl = this,
                _translate = $filter('translate'),
                _getCitiesPromise,
                _houseNumberRegExp = new RegExp(/^[0-9]+$/),
                _wasText1HouseNumberShown = false,
                _listeners = [],
                _addressComponents = ['city', 'text1', 'zipCode'],
                _ADDRESS_TYPE_MAP = {
                    city: 'city',
                    text1: 'address',
                    zipCode: 'zipCode'
                };

            if (!checkoutProcessCtrl.isAddressFirst && !checkoutProcessCtrl.timesDetails) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.times', 500);
            }

            checkoutProcessCtrl.nextView = nextView;
            checkoutProcessCtrl.previousView = previousView;
            checkoutProcessCtrl.validateView = validateView;
            checkoutProcessCtrl.getCityAutoCompleteOptions = getCityAutoCompleteOptions;
            checkoutProcessCtrl.getStreetAutoCompleteOptions = getStreetAutoCompleteOptions;
            checkoutProcessCtrl.onCityChange = onCityChange;
            checkoutProcessCtrl.onCityBlur = onCityBlur;
            checkoutProcessCtrl.onCityChosen = onCityChosen;
            checkoutProcessCtrl.onAddressChange = onAddressChange;
            checkoutProcessCtrl.onAddressBlur = onAddressBlur;
            checkoutProcessCtrl.onAddressChosen = onAddressChosen;
            checkoutProcessCtrl.onZipCodeBlur = onZipCodeBlur;
            checkoutProcessCtrl.onZipCodeChange = onZipCodeChange;
            checkoutProcessCtrl.getAddressAutoCompleteOptions = getAddressAutoCompleteOptions;
            checkoutProcessCtrl.extractAddressField = extractAddressField;
            checkoutProcessCtrl.isExistedInSuggestions = isExistedInSuggestions;
            checkoutProcessCtrl.onAddressInputKeydown = onAddressInputKeydown;
            checkoutProcessCtrl.openGoogleMapDialog = openGoogleMapDialog;
            checkoutProcessCtrl.onCountryBlur = onCountryBlur;

            checkoutAddressCtrl.validateText1HouseNumber = validateText1HouseNumber;
            checkoutAddressCtrl.backupAddress = {};
            checkoutAddressCtrl.editUnrecognizedAddress = editUnrecognizedAddress;
            checkoutAddressCtrl.cancelPinnedAddress = cancelPinnedAddress;
            checkoutAddressCtrl.getFieldErrorMsg = getFieldErrorMsg;
            checkoutAddressCtrl.isJustDropedNotDetectedAddress = isJustDropedNotDetectedAddress;
            checkoutAddressCtrl.isShowAddressField = isShowAddressField;
            checkoutAddressCtrl.isDisableAddressField = isDisableAddressField;
            checkoutAddressCtrl.isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields;
            checkoutAddressCtrl.onDroppinAddressFieldBlur = onDroppinAddressFieldBlur;
            checkoutAddressCtrl.editDroppinAddressField = editDroppinAddressField;

            checkoutProcessCtrl.isEnabledAddressSettings = isEnabledAddressSettings;
            checkoutProcessCtrl.addressDetails = checkoutProcessCtrl.addressDetails || {};
            checkoutProcessCtrl.suggestedCities = [];
            checkoutProcessCtrl.suggestedAddresses = [];
            checkoutProcessCtrl.isAddressVerificationEnabled = false;
            checkoutProcessCtrl.addressVerificationText = Config.retailer.settings.addressVerificationText ? JSON.parse(Config.retailer.settings.addressVerificationText) : {};
            checkoutProcessCtrl.formErrorCtrl = {
                city: false,
                text1: false,
                zipCode: false
            };
            checkoutProcessCtrl.droppinDisableFields = {
                city: true,
                text1: true,
                zipCode: true,
                state: true
            };
            checkoutProcessCtrl.isRunAddressAutoComplete = true;
            checkoutProcessCtrl.zipCodeLimitLength = zipCodeLimitLength;

            var _addressFormElementIds = {
                city: '#city_input',
                address: '#address_input',
                zipCode: '#zip_code_input',
                entrance: '#entry_input',
                apartment: '#apartment_input',
                buildingCode: '#buildingCode_input',
                state: '#state_input',
                comments: '#text_2_input',
                street: '#street_input',
                houseNumber: '#house_number_input',
                floor: '#floor_input',
            }

            var _addressFormFields = {
                city: 'city',
                address: 'address',
                zipCode: 'zipCode',
                entrance: 'entrance',
                apartment: 'apartment',
                buildingCode: 'buildingCode',
                state: 'state',
                comments: 'comments',
                street: 'street',
                houseNumber: 'houseNumber',
                floor: 'floor',
            }

            Config.checkoutData.state = STATE_NAME;
            Config.checkoutData.addressDetails = checkoutProcessCtrl.addressDetails;
            checkoutProcessCtrl.isZipCodeValid = false;
            checkoutProcessCtrl.isValidFloor = true;

            ShippingTermsPopup.showWithRedirect('app.cart'); 

            User.getUserSettings().then(function (user) {
                if (!user) return;
                var address = user.addresses && user.addresses.length ? user.addresses[0] : {},
                    userPhone = {};
                angular.forEach(user.phones, function (phone) {
                    if (phone.typeVal === PHONE_TYPES.MOBILE) {
                        userPhone = phone;
                    }
                });
                checkoutProcessCtrl.addressDetails.phone = checkoutProcessCtrl.addressDetails.phone || userPhone.phoneNumber;
                checkoutProcessCtrl.addressDetails.addressId = checkoutProcessCtrl.addressDetails.addressId || address.id;
                checkoutProcessCtrl.addressDetails.text1 = checkoutProcessCtrl.addressDetails.text1 || address.text1 || '';
                checkoutProcessCtrl.addressDetails.text2 = checkoutProcessCtrl.addressDetails.text2 || address.text2 || '';
                checkoutProcessCtrl.addressDetails.country = checkoutProcessCtrl.addressDetails.country || address.country || '';
                checkoutProcessCtrl.addressDetails.floor = checkoutProcessCtrl.addressDetails.floor || address.floor || null;
                checkoutProcessCtrl.addressDetails.apartment = checkoutProcessCtrl.addressDetails.apartment || address.apartment || '';
                checkoutProcessCtrl.addressDetails.city = checkoutProcessCtrl.addressDetails.city || address.city;
                checkoutProcessCtrl.addressDetails.street = checkoutProcessCtrl.addressDetails.street || address.street;
                checkoutProcessCtrl.addressDetails.state = checkoutProcessCtrl.addressDetails.state || address.state;
                checkoutProcessCtrl.addressDetails.buildingCode = checkoutProcessCtrl.addressDetails.buildingCode || address.buildingCode;
                checkoutProcessCtrl.addressDetails.houseNumber = checkoutProcessCtrl.addressDetails.houseNumber || address.houseNumber;
                checkoutProcessCtrl.addressDetails.entry = checkoutProcessCtrl.addressDetails.entry || address.entry;
                checkoutProcessCtrl.addressDetails.friendlyName = checkoutProcessCtrl.addressDetails.friendlyName || ((user.firstName || '') + ' ' + (user.lastName || '')).trim() || address.friendlyName || '';
                checkoutProcessCtrl.addressDetails.zipCode = checkoutProcessCtrl.addressDetails.zipCode || address.zipCode;
                checkoutProcessCtrl.addressDetails.externalPlaceId = checkoutProcessCtrl.addressDetails.externalPlaceId || address.externalPlaceId;
                checkoutProcessCtrl.addressDetails.lat = checkoutProcessCtrl.addressDetails.lat || address.lat;
                checkoutProcessCtrl.addressDetails.lng = checkoutProcessCtrl.addressDetails.lng || address.lng;
                checkoutProcessCtrl.isGMapWithZipCodeProvider = Config.retailer.settings.autocompleteAddressField && Config.retailer.settings.activeZipProvider && Config.retailer.settings.activeZipProvider.name === 'Get Address'
                if (Config.retailer.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                    getCityAutoCompleteOptions().then(function (citiesList) {
                        if (!citiesList) {
                            return;
                        }

                        var foundCity = _findCityAndSetIsStreetRequired(citiesList);
                        if (!foundCity) {
                            delete checkoutProcessCtrl.addressDetails.city;
                        }
                    });
                }
                try {
                    var addressComponents = JSON.parse(localStorage.getItem('mobileZuzTypedAreaAddressComponents'));
                    var addressFields = util.checkAddressFields(checkoutProcessCtrl.addressDetails);
                    if (addressComponents && !addressFields && !Config.retailer.settings.autocompleteAddressField) {
                        angular.extend(
                            checkoutProcessCtrl.addressDetails,
                            SpDeliveryAreasService.addressComponentsToSpAddress(addressComponents)
                        );
                    }
                } catch (err) {
                    // In case "mobileZuzTypedAreaAddressComponents" is not array
                }
                // Backup address
                angular.extend(checkoutAddressCtrl.backupAddress, checkoutProcessCtrl.addressDetails);
                // Init previous full delivery address
                angular.extend(checkoutProcessCtrl.previousFullDeliveryAddress, {
                    address: address,
                    externalPlaceId: address.externalPlaceId,
                });

                // pre-validate zipcode
                onZipCodeBlur();

                _setOrInitValueForAddressField('isShowEditAddress', checkoutProcessCtrl.addressDetails.isShowEditAddress, false);
                _setOrInitValueForAddressField('hasHouseNumberAndRoute', checkoutProcessCtrl.addressDetails.hasHouseNumberAndRoute, !checkoutProcessCtrl.isDropPinAddressNotDetected);
                _setOrInitValueForAddressField('isDisabledAddressField', checkoutProcessCtrl.addressDetails.isDisabledAddressField, false);

            });

            Retailer.getRetailerSettings().then(function (response) {
                if (checkoutProcessCtrl.isDelivery) {
                    // TODO remove checkoutProcessCtrl.streetIsRequire
                    checkoutProcessCtrl.streetIsRequire = response.deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY_STREET_NOT_REQUIRED;
                    checkoutProcessCtrl.deliveryAreaMethod = response.deliveryAreaMethod;
                    // For feature address verification
                    var isEnable = Config.retailer.settings.isAddressVerificationEnabled && Config.retailer.settings.isAddressVerificationEnabled === 'true';
                    var isUseGoogleMapApi = [SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON, SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS].includes(checkoutProcessCtrl.deliveryAreaMethod);
                    checkoutProcessCtrl.isAddressVerificationEnabled = isEnable && isUseGoogleMapApi;
                    checkoutProcessCtrl.isUseGMapDeliveryMethod = isUseGoogleMapApi;

                    if (
                        // is in pilot updates
                        checkoutProcessCtrl.isEnabledAddressSettings &&
                        // is GOOGLE_MAPS / POLYGON
                        checkoutProcessCtrl.isUseGMapDeliveryMethod
                    ) {
                        // GOOGLE_MAPS / POLYGON with Zip-code provider will use old form => don't need to load form settings
                        if (checkoutProcessCtrl.isGMapWithZipCodeProvider) {
                            checkoutProcessCtrl.streetIsRequire  = Config.retailer.settings.addressPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY_STREET_NOT_REQUIRED;
                            return;
                        } 
        
                        checkoutProcessCtrl.streetIsRequire = true;
                        delete Config.retailer.settings.enableDefaultCountry;

                        checkoutProcessCtrl.isGoogleMapDropPinAllow = Config.retailer.settings.isGoogleMapDropPinAllow && Config.retailer.settings.isGoogleMapDropPinAllow === 'true';
                        checkoutProcessCtrl.isGoogleMapDropPinValidateHouseNumAndStreet = Config.retailer.settings.isGoogleMapDropPinValidateHouseNumAndStreet && Config.retailer.settings.isGoogleMapDropPinValidateHouseNumAndStreet === 'true';
        
                        // already have address form settings
                        if (!_isNullOrEmpty(Config.retailer.settings.addressFormSettings) && !_isNullOrEmpty(Config.retailer.settings.enableAddressFiltering) && !_isNullOrEmpty(Config.retailer.settings.addressFiltering)) {
                            checkoutProcessCtrl.addressFormSettings = JSON.parse(Config.retailer.settings.addressFormSettings);
                    
                            checkoutProcessCtrl.enableAddressFiltering = Config.retailer.settings.enableAddressFiltering === 'true';
                            checkoutProcessCtrl.addressFiltering = Number(Config.retailer.settings.addressFiltering);
                            checkoutProcessCtrl.isAddressFilteringCityMode = checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                            checkoutProcessCtrl.isAddressFilteringZipCodeMode = checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                        } else {
                            // haven't had address form settings yet
                            AddressService.getAddressFormSettings().then(function (formSettings) {
                                checkoutProcessCtrl.addressFormSettings = formSettings.settings;

                                checkoutProcessCtrl.enableAddressFiltering = !!formSettings.mode;
                                checkoutProcessCtrl.addressFiltering = formSettings.mode;
                                checkoutProcessCtrl.isAddressFilteringCityMode = checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                                checkoutProcessCtrl.isAddressFilteringZipCodeMode = checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                            })
                        }
                    }
                }
                checkoutProcessCtrl.enableFloorApartment = response.enableFloorApartment;
            });

            if (!checkoutProcessCtrl.isDelivery && checkoutProcessCtrl.areas && checkoutProcessCtrl.areas.length) {
                checkoutProcessCtrl.setAreaByConfigAreaId();
            }

            _init()

            function _init() {
                getAddressFromMap();
                if (Config.retailer.settings.enableDefaultCountry) {
                    _addressComponents.push('country');
                }
            }
            
            // prevent case false (boolean)
            function _isNullOrEmpty(value){
                if (value === null || value === undefined || value === '') {
                    return true;
                }
                
                if (typeof value === 'string' && value.trim() === '') {
                    return true;
                }
            
                return false;
            }

            function _isInvalidMandatoryFieldValue(fieldSetting, fieldValue){
                fieldSetting = fieldSetting || {};
                return fieldSetting.isEnabled && fieldSetting.isMandatory && _isNullOrEmpty(fieldValue);
            }

            function getInvalidFormElement(addressFormSettings) {
                if (!checkoutProcessCtrl.enableAddressFiltering || checkoutProcessCtrl.isAddressFilteringCityMode) {
                    var _invalidMandatoryField = _validateMandatoryFieldsWithOrdering(addressFormSettings, [
                        _addressFormFields.city,
                        _addressFormFields.address,
                        _addressFormFields.street,
                        _addressFormFields.houseNumber,
                        _addressFormFields.zipCode,
                        _addressFormFields.entrance,
                        _addressFormFields.floor,
                        _addressFormFields.apartment,
                        _addressFormFields.buildingCode,
                        _addressFormFields.state,
                        _addressFormFields.comments,
                    ]);
                    if (_invalidMandatoryField) return _invalidMandatoryField;

                } else if (checkoutProcessCtrl.isAddressFilteringZipCodeMode) {
                    var _invalidMandatoryField = _validateMandatoryFieldsWithOrdering(addressFormSettings, [
                        _addressFormFields.zipCode,
                        _addressFormFields.address,
                        _addressFormFields.street,
                        _addressFormFields.houseNumber,
                        _addressFormFields.floor,
                        _addressFormFields.apartment,
                        _addressFormFields.state,
                        _addressFormFields.city,
                        _addressFormFields.comments,
                        _addressFormFields.entrance,
                        _addressFormFields.buildingCode,
                    ]);
                    if (_invalidMandatoryField) return _invalidMandatoryField;
                }

                return null;
            }

            function _validateMandatoryFieldsWithOrdering(addressFormSettings, ordering){
                for (var i = 0; i < ordering.length; i++) {
                    var field = ordering[i];
                    switch (field) {
                        case _addressFormFields.city:{
                            var citySetting = addressFormSettings.city;
                            var city = extractAddressField('city');

                            if (_isInvalidMandatoryFieldValue(citySetting, city)) return _addressFormElementIds.city;
                            break;
                        }
                        case _addressFormFields.zipCode:{
                            var zipCodeSetting = addressFormSettings.zipCode;
                            var zipCode = extractAddressField('zipCode');

                            if (_isInvalidMandatoryFieldValue(zipCodeSetting, zipCode)) return _addressFormElementIds.zipCode;
                            break;
                        }
                        case _addressFormFields.entrance:{
                            var entranceSetting = addressFormSettings.entrance;
                            var entry = extractAddressField('entry');
                            
                            if (_isInvalidMandatoryFieldValue(entranceSetting, entry)) return _addressFormElementIds.entrance;
                            break;
                        }
                        case _addressFormFields.apartment:{
                            var apartmentSetting = addressFormSettings.apartment;
                            var apartment = extractAddressField('apartment');

                            if (_isInvalidMandatoryFieldValue(apartmentSetting, apartment)) return _addressFormElementIds.apartment;
                            break;
                        }
                        case _addressFormFields.buildingCode:{
                            var buildingCodeSetting = addressFormSettings.buildingCode;
                            var buildingCode = extractAddressField('buildingCode');

                            if (_isInvalidMandatoryFieldValue(buildingCodeSetting, buildingCode)) return _addressFormElementIds.buildingCode;
                            break;
                        }
                        case _addressFormFields.state:{
                            var stateSetting = addressFormSettings.state;
                            var state = extractAddressField('state');

                            if (_isInvalidMandatoryFieldValue(stateSetting, state)) return _addressFormElementIds.state;
                            break;
                        }
                        case _addressFormFields.comments:{
                            var commentsSetting = addressFormSettings.comments;
                            var comment = extractAddressField('text2');

                            if (_isInvalidMandatoryFieldValue(commentsSetting, comment)) return _addressFormElementIds.comments;
                            break;
                        }
                        case _addressFormFields.address:{
                            if(isShowAddressField()){
                                var addressSetting = addressFormSettings.address;
                                var text1 = extractAddressField('text1');
                                
                                if (_isInvalidMandatoryFieldValue(addressSetting, text1)) return _addressFormElementIds.address;
                            }
                            break;
                        }
                        case _addressFormFields.street:{
                            var _isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields();
                            if(_isShowStreetAndHouseNumberFields){
                                var street = extractAddressField('street');
                                if(_isNullOrEmpty(street)) return _addressFormElementIds.street;
                            }
                            break;
                        }
                        case _addressFormFields.houseNumber:{
                            var _isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields();
                            if(_isShowStreetAndHouseNumberFields){
                                var houseNumber = checkoutProcessCtrl.addressDetails.houseNumber;
                                if(_isNullOrEmpty(houseNumber)) return _addressFormElementIds.houseNumber;
                            }
                            break;
                        }
                        case _addressFormFields.floor: {
                            var floorSetting = addressFormSettings.floor || {};
                            var floor = checkoutProcessCtrl.addressDetails.floor;
                            if (_isInvalidMandatoryFieldValue(floorSetting, floor) || (floorSetting.isEnabled && !checkoutProcessCtrl.isValidFloor)) return _addressFormElementIds.floor;
                            break;
                        }
                    }
                }

                return null;
            }

            function getAddressFromMap() {
                var adrsDetailsParams = $state.params.addressDetails;
                if (adrsDetailsParams) {
                    _handleSelectedGoogleMapAddress(JSON.parse(adrsDetailsParams));
                }
            }

            function getCityAutoCompleteOptions() {
                var city = extractAddressField('city');
                if (!city) {
                    return $q.resolve([]);
                }

                var citiesDefer = $q.defer();
                _getCitiesPromise = citiesDefer.promise;
                var getRequestFunc = checkoutProcessCtrl.isAddressVerificationEnabled ? _getCitiesFromGoogleMapApi : _getInternalCities;

                return getRequestFunc(city).then(function (data) {
                    checkoutProcessCtrl.suggestedCities = data.cities;
                    citiesDefer.resolve(data.cities);
                    return data.cities;
                }).catch(function (err) {
                    citiesDefer.reject(err);
                    return _getCitiesPromise;
                });
            }

            function getStreetAutoCompleteOptions() {
                if (!checkoutProcessCtrl.addressDetails.street) {
                    return $q.resolve([]);
                }

                return $q.resolve(_getCitiesPromise).then(function(cities) {
                    if (!cities) {
                        return $q.reject('no cities');
                    }

                    var cityId;
                    angular.forEach(cities, function (city) {
                        if (city.name === checkoutProcessCtrl.addressDetails.city) {
                            cityId = city.id;
                        }
                    });

                    if (!cityId) {
                        return $q.reject('city not found');
                    }

                    return cityId;
                }).then(function(cityId) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/addresses/streets',
                        params: {
                            term: checkoutProcessCtrl.addressDetails.street,
                            cityId: cityId,
                            size: 8
                        }
                    });
                }).then(function (data) {
                    var streetsName = [];

                    angular.forEach(data.streets, function (street) {
                        streetsName.push(street.name);
                    });

                    return streetsName;
                });
            }

            function onCityChange() {
                if (checkoutProcessCtrl.isAddressVerificationEnabled && !(checkoutProcessCtrl.isGoogleMapDropPinAllow && !checkoutProcessCtrl.addressDetails.hasHouseNumberAndRoute)) {
                    $rootScope.$emit('checkout.address.delivery.hideFormError', {
                        hiddenFields: ['city'],
                    });
                    return;
                }

                $timeout(function() {
                    $rootScope.$emit('checkout.city.change');

                    if (_getCitiesPromise) {
                        _getCitiesPromise.then(function(cities) {
                            _findCityAndSetIsStreetRequired(cities);
                        });
                    }
                }, 100);
            }

            function onZipCodeChange() {
                if (!checkoutProcessCtrl.isAddressFilteringZipCodeMode) {
                    return
                }
                if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                    $scope.$emit('checkout.address.delivery.hideFormError', {
                        hiddenFields: ['zipCode'],
                    });
                    return;
                }
            }

            function _findCityAndSetIsStreetRequired(cities) {
                for (var i = 0; i < cities.length; i++) {
                    if (cities[i].name === checkoutProcessCtrl.addressDetails.city) {
                        checkoutProcessCtrl.streetIsRequire = checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE && cities[i].type === CITY_TYPES.MULTIPLE_ZIP_CODES;
                        return cities[i];
                    }
                }
            }

            function nextView() {
                checkoutProcessCtrl.addressDetails.entry = _getEntryValue(checkoutProcessCtrl.addressDetails.entry);
                if (checkoutProcessCtrl.isAddressFirst) {
                    return checkoutProcessCtrl.goToState('app.cart.checkout.process.times');
                }
                checkoutProcessCtrl.goToState('app.cart.checkout.process.' + (checkoutProcessCtrl.isPaymentLast() ? 'summary' : 'payment'));
            }

            function previousView() {
                if (checkoutProcessCtrl.isAddressFirst) return;

                checkoutProcessCtrl.addressDetails.entry = _getEntryValue(checkoutProcessCtrl.addressDetails.entry);
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.times');
            }

            //set "None" value as null
            function _getEntryValue(entry) {
                if (entry === _translate(HOUSE_ENTRIES[0])) {
                    return null;
                }
                return entry;
            }

            function validatePhone () {
                if(checkoutProcessCtrl.mobilePhone.customValidated){
                    checkoutProcessCtrl.addressDetails.phone = [
                        checkoutProcessCtrl.mobilePhone.areaCode ? checkoutProcessCtrl.mobilePhone.areaCode.toString() : '',
                        checkoutProcessCtrl.mobilePhone.customPhoneNumber ? checkoutProcessCtrl.mobilePhone.customPhoneNumber.toString() : ''
                    ].join('');
                }
                return Api.request({
                    method: 'POST',
                    url: '/v1/retailers/:rid/phone-number/is-valid',
                    data: {
                        phoneNumber: checkoutProcessCtrl.addressDetails.phone,
                        phoneId: checkoutProcessCtrl.mobilePhone.id,
                        countryCode: checkoutProcessCtrl.mobilePhone.countryCode,
                        areaCode: checkoutProcessCtrl.mobilePhone.areaCode,
                        customValidated: checkoutProcessCtrl.mobilePhone.customValidated,
                        customPhoneNumber: checkoutProcessCtrl.mobilePhone.customPhoneNumber
                    }
                });
            }

            function cancelPinnedAddress() {
                checkoutProcessCtrl.isDropPinAddress = false;
                checkoutProcessCtrl.isDropPinAddressNotDetected = false;
                checkoutProcessCtrl.droppinDisableFields.city = false;
                checkoutProcessCtrl.droppinDisableFields.text1 = false;
                checkoutProcessCtrl.droppinDisableFields.zipCode = false;
                checkoutProcessCtrl.droppinDisableFields.state = false;
                
                _setOrInitValueForAddressField('zipCode', '');
                _setOrInitValueForAddressField('text1', '');
                _setOrInitValueForAddressField('city', '');
                _setOrInitValueForAddressField('street', '');
                _setOrInitValueForAddressField('houseNumber', '');
                _setOrInitValueForAddressField('state', '');

                _setOrInitValueForAddressField('hasHouseNumberAndRoute', true);
            }


            function _validateAddressFields() {
                return validatePhone().then(function (isValidPhone) {
                    if(!isValidPhone) {
                        return mDesign.alert('{{(\'Phone number is not valid.\' | translate)}}');
                    }
                    var checkoutForm = document.querySelector('#address_form'),
                        form = angular.element(checkoutForm).data('$formController');

                    if(!checkoutForm){
                        return
                    }

                    if (!checkoutProcessCtrl.addressDetails.friendlyName || !checkoutProcessCtrl.addressDetails.friendlyName.trim()) {
                        return {selector: angular.element(checkoutForm.querySelector('#friendly_name'))};
                    }
                    if (!checkoutProcessCtrl.addressDetails.phone || !isValidPhone) {
                        return {selector: angular.element(checkoutForm.querySelector('#phone_number')), isPhoneValid: isValidPhone};
                    }

                    if (checkoutProcessCtrl.isEnabledAddressSettings) {
                        // validate base on address form settings
                        if (checkoutProcessCtrl.isUseGMapDeliveryMethod) {
                            // special flow for Zip code Provider - 'Get Address'
                            if (checkoutProcessCtrl.isGMapWithZipCodeProvider) {
                                if (checkoutProcessCtrl.streetIsRequire && (!(checkoutProcessCtrl.addressDetails.lat && checkoutProcessCtrl.addressDetails.lng) && (!checkoutProcessCtrl.addressDetails.text1 ||
                                    !checkoutProcessCtrl.addressDetails.text1.trim()))) {
                                    return { selector: angular.element(checkoutForm.querySelector('#address_text_input')) };
                                }

                                if (!checkoutProcessCtrl.addressDetails.city || !checkoutProcessCtrl.addressDetails.city.trim() ||
                                    (form['city'] && form['city'].$invalid)) {
                                    return { selector: angular.element(checkoutForm.querySelector('#city_input')) };
                                }
                                
                            } else {
                                // normal flow
                                var element = getInvalidFormElement(checkoutProcessCtrl.addressFormSettings)
                                if (element) {
                                    return { selector: angular.element(checkoutForm.querySelector(element)) };
                                }
                            }

                        }

                        if (
                            checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE ||
                            checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY
                        ) {
                            if (!checkoutProcessCtrl.addressDetails.city || !checkoutProcessCtrl.addressDetails.city.trim() ||
                                (form['city'] && form['city'].$invalid)) {
                                return { selector: angular.element(checkoutForm.querySelector('#city_input')) };
                            }

                            if (checkoutProcessCtrl.streetIsRequire) {
                                if (!checkoutProcessCtrl.addressDetails.street || !checkoutProcessCtrl.addressDetails.street.trim() ||
                                    (form['street'] && form['street'].$invalid)) {
                                    return { selector: angular.element(checkoutForm.querySelector('#street_input')) };
                                }
                                if (!checkoutProcessCtrl.addressDetails.houseNumber) {
                                    return { selector: angular.element(checkoutForm.querySelector('#house_number_input')) };
                                }
                            }
                        }
                    } else {
                        if (checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON ||
                            checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS
                        ) {
                            if (checkoutProcessCtrl.streetIsRequire && (!(checkoutProcessCtrl.addressDetails.lat && checkoutProcessCtrl.addressDetails.lng) && (!checkoutProcessCtrl.addressDetails.text1 ||
                                !checkoutProcessCtrl.addressDetails.text1.trim()))) {
                                return {selector: angular.element(checkoutForm.querySelector('#address_text_input'))};
                            }
                        }
                        if (!checkoutProcessCtrl.addressDetails.city || !checkoutProcessCtrl.addressDetails.city.trim() ||
                            (form['city'] && form['city'].$invalid)) {
                            return {selector: angular.element(checkoutForm.querySelector('#city_input'))};
                        }
                        if (checkoutProcessCtrl.streetIsRequire && (
                            checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE ||
                            checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY
                        )) {
                            if (!checkoutProcessCtrl.addressDetails.street || !checkoutProcessCtrl.addressDetails.street.trim() ||
                                (form['street'] && form['street'].$invalid)) {
                                return {selector: angular.element(checkoutForm.querySelector('#street_input'))};
                            }
                            if (!checkoutProcessCtrl.addressDetails.houseNumber) {
                                return {selector: angular.element(checkoutForm.querySelector('#house_number_input'))};
                            }
                        }
                    }

                });

            }

            function _validatePickupFields() {
                return validatePhone().then(function (isValidPhone) {
                    if(!isValidPhone) {
                        return mDesign.alert('Phone number is not valid.');
                    }
                    var checkoutForm = document.querySelector('#address_form');
                    if (!checkoutProcessCtrl.addressDetails.friendlyName || !checkoutProcessCtrl.addressDetails.friendlyName.trim()) {
                        return {selector: angular.element(checkoutForm.querySelector('#friendly_name'))};
                    }
                    if (!checkoutProcessCtrl.addressDetails.phone || !isValidPhone) {
                        return {selector: angular.element(checkoutForm.querySelector('#phone_number')), isPhoneValid: isValidPhone};
                    }
                    if (!branchArea) {
                        return {msg: 'Pickup location is required'};
                    }
                    if (checkoutProcessCtrl.areas && checkoutProcessCtrl.areas.length && !(checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.branchArea)) {
                        return {msg: 'Pickup location is required'};
                    }
                });

            }

            function validateView(defer, options) {
                var emptyFieldErr = checkoutProcessCtrl.isDelivery ? _validateAddressFields() : _validatePickupFields();

                return emptyFieldErr.then(function (emptyFieldError) {

                    if (!checkoutProcessCtrl.isDelivery || !!emptyFieldError) {
                        checkoutAddressCtrl.showErrors = true;

                        if (emptyFieldError && (emptyFieldError.selector && emptyFieldError.selector[0] || !emptyFieldError.isPhoneValid)) {
                            setTimeout(function () {
                                var checkoutContainer = document.querySelector('.checkout-container');
                                if (checkoutContainer && emptyFieldError.selector[0]) {
                                    angular.element(checkoutContainer).scrollTop(emptyFieldError.selector[0].offsetTop, 100);
                                }
                            });
                        }

                        return emptyFieldError;
                    }

                    var houseNumberErr = _validateText1HouseNumber();
                    if (houseNumberErr) {
                        return houseNumberErr;
                    }

                    return checkoutProcessCtrl.getDeliveryTimes(defer, options);
                });
            }

            /**
             * Wrapper for _validateText1HouseNumber, to focus on the returned selector
             * @public
             *
             * @returns {Promise<void>|void}
             */
            function validateText1HouseNumber() {
                var promise = _validateText1HouseNumber();
                if (!promise) {
                    return;
                }

                return promise.then(function(err) {
                    if (err.selector) {
                        err.selector.focus();
                    }
                });
            }

            /**
             * Validates the text1 contains a house number
             * Returns a validateView format error after showing the dialog
             * @private
             *
             * @returns {Promise<Object>|void}
             */
            function _validateText1HouseNumber() {
                var isIgnoreValidation
                
                // TODO remove pilot check 
                if(checkoutProcessCtrl.isEnabledAddressSettings){
                    isIgnoreValidation = [
                        _wasText1HouseNumberShown,
                        !checkoutProcessCtrl.isDelivery,
                        !checkoutProcessCtrl.addressDetails.text1,
                        !checkoutProcessCtrl.isUseGMapDeliveryMethod, 
                        !checkoutProcessCtrl.streetIsRequire,
                        //checkoutProcessCtrl.addressDetails.externalPlaceId,
                        //checkoutProcessCtrl.addressDetails.lat && checkoutProcessCtrl.addressDetails.lng,
                    ].some(function (value) {
                        return value;
                    });
                }else{
                    var deliveryAreaPrecision = $rootScope.config.retailer.deliveryAreaPrecision;
                    isIgnoreValidation = [
                        _wasText1HouseNumberShown,
                        !checkoutProcessCtrl.isDelivery,
                        !checkoutProcessCtrl.addressDetails.text1,
                        //checkoutProcessCtrl.addressDetails.externalPlaceId,
                        //checkoutProcessCtrl.addressDetails.lat && checkoutProcessCtrl.addressDetails.lng,
                        deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.PRECISE_LOCATION && deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY
                    ].some(function (value) {
                        return value;
                    });
                }
                if (isIgnoreValidation) {
                    return;
                }

                var _text1 = checkoutProcessCtrl.addressDetails.text1;
                var splitAddress = _text1.replace(/,/g, ' ');
                splitAddress = splitAddress.replace(/\s+/g, ' ');
                splitAddress = splitAddress.split(' ');

                var hasHouseNumber = splitAddress.length >= 2 && splitAddress.some(function(word) {
                    return _houseNumberRegExp.test(word);
                });
                if (hasHouseNumber) {
                    return;
                }

                _wasText1HouseNumberShown = true;
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/address-number-warning.html',
                    controller: ['$scope', function ($scope) {
                        $scope.hide = mDesign.hide;
                    }]
                }).then(function() {
                    return { selector:  angular.element(document.querySelector(_addressFormElementIds.address)) };
                });
            }

            function _sendDataLayerEvent() {
                var cartLineProducts = [];
                angular.forEach(Cart.lines, function (line) {
                    if(line && line.product && line.product.id) {
                        line.product.quantity = line.quantity;
                        cartLineProducts.push(line.product);
                    }
                });
                DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 2, option: 'user address'}});
            }

            _listeners.push($rootScope.$on('cart.update.complete', _sendDataLayerEvent));

            util.destroyListeners($scope, _listeners);

            Cart.save();

            function onCityBlur(event) {
                if(_isClickToSuggestion(event)) return;

                var _isCityChanged = _isLocationChanged('city');
                $rootScope.$emit('checkout.details.location.blur', 'city');

                if(checkoutProcessCtrl.isEnabledAddressSettings){
                    if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                        var city = extractAddressField('city');
                        var isExisted = isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, checkoutProcessCtrl.suggestedCities);
                        if (!isExisted) {
                            $rootScope.$emit('checkout.address.delivery.setFormError', 'city');
                        }

                        if(checkoutProcessCtrl.isAddressFilteringCityMode && _isCityChanged){
                            checkoutProcessCtrl.suggestedAddresses = [];
                            checkoutProcessCtrl.addressDetails.text1 = '';
                            checkoutProcessCtrl.suggestedZipCodes = [];
                            checkoutProcessCtrl.addressDetails.zipCode = '';
                        }
                    }
                } else {
                    if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                        var city = extractAddressField('city');
                        var isExisted = isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, checkoutProcessCtrl.suggestedCities);
                        if (!isExisted) {
                            $rootScope.$emit('checkout.address.delivery.setFormError', 'city');
                        }
                    }
                }
            }

            function onZipCodeBlur() {
                if (!checkoutProcessCtrl.isAddressFilteringZipCodeMode) {
                    return;
                }
                
                var _isZipCodeChanged = _isLocationChanged('zipCode');
                $rootScope.$emit('checkout.details.location.blur', 'zipCode');

                if (checkoutProcessCtrl.isAddressVerificationEnabled && _isZipCodeChanged) {
                    checkoutProcessCtrl.suggestedAddresses = [];
                    checkoutProcessCtrl.suggestedCities = [];
                    checkoutProcessCtrl.addressDetails.text1 = '';
                    checkoutProcessCtrl.addressDetails.city = '';
                }
                
                if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                    var zipCode = extractAddressField('zipCode');
                    if (!zipCode) {
                        return $q.resolve([]);
                    }

                    //generate another promise that will return the full cities array (no only names)
                    var zipCodesDefer = $q.defer();
                    var getRequestFunc = _getZipCodeFromGoogleMapApi;

                    return getRequestFunc(zipCode).then(function (data) {
                        checkoutProcessCtrl.suggestedZipCodes = data.zipCodes;
                        var isExisted = isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, checkoutProcessCtrl.suggestedZipCodes);
                        if (!isExisted) {
                            $rootScope.$emit('checkout.address.delivery.setFormError', 'zipCode');
                            checkoutProcessCtrl.isZipCodeValid = false;
                        } else {
                            $rootScope.$emit('checkout.address.delivery.hideFormError', {
                                hiddenFields: ['zipCode'],
                            });
                            checkoutProcessCtrl.isZipCodeValid = !!checkoutProcessCtrl.suggestedZipCodes.length;
                        }

                        zipCodesDefer.resolve(data.zipCodes);
                        return data.zipCodes;
                    }).catch(function (err) {
                        zipCodesDefer.reject(err);
                    });
                }
            }

            function onCityChosen($event) {
                var cityComponent = $event && $event.option && $event.option.component;
                
                if ($event.option.placeId) {
                    cityComponent.placeId = $event.option.placeId;
                }

                if (cityComponent) {
                    angular.extend(checkoutProcessCtrl.addressDetails, cityComponent);
                    $rootScope.$emit('checkout.address.delivery.hideFormError', {
                        hiddenFields: ['city'],
                    });
                }
                
                onCityBlur();
                return;
            }

            /**
             * @param {string} field field of address
             * @returns { string | null }
             */
            function extractAddressField(field) {
                return checkoutProcessCtrl.addressDetails && checkoutProcessCtrl.addressDetails[field] ? checkoutProcessCtrl.addressDetails[field].trim() : null;
            }

            /**
             * @param {string[]} placeTypes 
             * @returns {boolean}
             */
            function _isAddrSuggestionValid(placeTypes) {
                // The "text1" is the address detail
                var isInvalidPlaceType = [GOOGLE_MAP_ADDRESS_TYPES.ROUTE].some(function (type) {
                    return placeTypes.includes(type);
                });
                return !isInvalidPlaceType;
            }

            function _getInternalCities(cityQuery) {
                return AddressService.getInternalCities(cityQuery);
            }
            
            /**
             * @param {string} cityQuery 
             */
            function _getCitiesFromGoogleMapApi(cityQuery) {
                return AddressService.getCitiesFromGoogleMapApi(cityQuery);
            }

            function _getZipCodeFromGoogleMapApi(zipCode) {
                return AddressService.getZipCodeFromGoogleMapApi(zipCode, '', zipCode, true);
            }

            function getAddressAutoCompleteOptions() {
                if (!checkoutProcessCtrl.isRunAddressAutoComplete) {
                    return $q.resolve([]);
                }

                var addressQuery = extractAddressField('text1');
                var _addressFilteringMode = checkoutProcessCtrl.isEnabledAddressSettings && checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering ? checkoutProcessCtrl.addressFiltering : null;

                // Call google api
                return AddressService.getAddressAutoCompleteOptions(addressQuery, {
                    addressFilteringMode:  _addressFilteringMode,
                    placeId: checkoutProcessCtrl.addressDetails.placeId,
                    city: checkoutProcessCtrl.addressDetails.city,
                    zipCode: checkoutProcessCtrl.addressDetails.zipCode,
                    languageId: $rootScope.config.language.id
                }).then(function (addresses) {
                    checkoutProcessCtrl.suggestedAddresses = addresses;
                    return addresses;
                });
            }

            function onAddressChange() {
                if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                    $rootScope.$emit('checkout.address.delivery.hideFormError', {
                        hiddenFields: ['text1'],
                    });
                }
            }
            
            function onAddressBlur(event) {
                if(_isClickToSuggestion(event)) return;

                var _isAddressChanged = _isLocationChanged('text1');
                if(_isAddressChanged){
                    checkoutProcessCtrl.isDropPinAddress = false;
                    checkoutProcessCtrl.isDropPinAddressNotDetected = false;
                    _setOrInitValueForAddressField('hasHouseNumberAndRoute', true);
                }

                var text1 = extractAddressField('text1');
                if (text1) {
                    _handleWithExistingText1(text1);
                } else {
                    _handleWithEmptyText1();
                }
            } 
            
            function onAddressChosen($event) {
                var addressComponent = $event && $event.option && $event.option.component;
                if (addressComponent) {
                    return AddressService.geocodeByGgMapApi({ googlePlaceId: addressComponent.externalPlaceId, languageId: $rootScope.config.language.id }).then(function (_res) {
                        var _gAddress = _res.results[0];
                        var extractedInfo = GMap.extractInfoFromAddressComponents(_gAddress.address_components);
                        var text1Obj = util.constructAdrsText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);

                        var _address = {
                            text1: text1Obj.value,
                            city: extractedInfo.city,
                            country: extractedInfo.country,
                            countryCode: extractedInfo.countryCode,
                            zipCode: extractedInfo.zipCode,
                            state: extractedInfo.state,
                            lng: _gAddress.geometry.location.lng,
                            lat: _gAddress.geometry.location.lat,
                            externalPlaceId: _gAddress.place_id,
                            hasHouseNumberAndRoute: true
                        }

                        if (checkoutProcessCtrl.isAddressFilteringCityMode) {
                            delete _address.city;
                        } else if (checkoutProcessCtrl.isAddressFilteringZipCodeMode) {
                            delete _address.zipCode;
                        }

                        checkoutProcessCtrl.isRunAddressAutoComplete = false;
                        angular.extend(checkoutProcessCtrl.addressDetails, _address);
                        angular.extend(checkoutAddressCtrl.backupAddress, checkoutProcessCtrl.addressDetails);
                        $rootScope.$emit('checkout.address.delivery.hideFormError', {
                            hiddenFields: ['city', 'text1'],
                        });

                        onAddressBlur();
                    })

                }
            }
            
            /**
             * @param {'city' | 'address'} addressType
             * @param {string} value 
             * @param {any[]} suggestions suggestedCities | suggestedAddresses
             * @returns boolean
             */
            function isExistedInSuggestions(addressType, value, suggestions) {
                var _address = checkoutProcessCtrl.addressDetails || {};
                var hasCoordinatesOrPlaceId = (_address.lat && _address.lng) || _address.externalPlaceId;
                if (!value || hasCoordinatesOrPlaceId) {
                    return true;
                }

                if(!suggestions || !suggestions.length) return false;

                for (var idx = 0; idx < suggestions.length; idx++) {
                    var item = suggestions[idx];
                    switch (addressType) {
                        case _ADDRESS_TYPE_MAP.city: {
                            if (item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase()) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.text1: {
                            var isValid = _isAddrSuggestionValid(item.placeTypes);
                            var isExisted = item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase();
                            if (isValid && isExisted) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.zipCode: {
                            if (item.mainValue && item.mainValue.toLowerCase().replace(/\s+/g, '') === value.toLowerCase().replace(/\s+/g, '')) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }

            /**
             * @param {any} event 
             * @param {string} errorField address field
             */
            function _setFormErrorEvent(event, errorField) {
                checkoutProcessCtrl.formErrorCtrl[errorField] = true;
            }

            /**
             * @param {any} event 
             * @param {{hiddenFields: string[]}} emitterData list address field
             */
            function _hideFormErrorEvent(event, emitterData) {
                angular.forEach(emitterData.hiddenFields, function (field) {
                    checkoutProcessCtrl.formErrorCtrl[field] = false;
                });
            }

            /**
             * @param {any} event 
             * @param {string} selector element selector
             */
            function _triggerInputBlur(event, selector) {
                var checkoutForm = document.querySelector('#address_form');
                var inputCityElement = checkoutForm && angular.element(checkoutForm.querySelector(selector));
                if (inputCityElement) {
                    inputCityElement.triggerHandler('blur');
                }
                checkoutAddressCtrl.showErrors = true;
            }

            function onAddressInputKeydown() {
                if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                    checkoutProcessCtrl.isRunAddressAutoComplete = true;
                }
            }

            function _handleSelectedGoogleMapAddress(selectedAddress) {
                if (!selectedAddress) {
                    return;
                }

                checkoutProcessCtrl.isDropPinAddress = true;
                checkoutProcessCtrl.isDropPinAddressNotDetected = !selectedAddress.hasHouseNumberAndRoute;
                        
                angular.extend(checkoutProcessCtrl.addressDetails, {
                    text1: selectedAddress.text1,
                    city: selectedAddress.city,
                    country: selectedAddress.country,
                    countryCode: selectedAddress.countryCode,
                    zipCode: selectedAddress.zipCode,
                    state: selectedAddress.state,
                    lat: selectedAddress.lat,
                    lng: selectedAddress.lng,
                    externalPlaceId: null,
                    hasHouseNumberAndRoute: selectedAddress.hasHouseNumberAndRoute,
                    isShowEditAddress: !selectedAddress.hasHouseNumberAndRoute,
                    isDisabledAddressField: !selectedAddress.hasHouseNumberAndRoute
                });

                $rootScope.$emit('checkout.address.delivery.hideFormError', {
                    hiddenFields: ['text1', 'city'],
                });
                angular.extend(checkoutAddressCtrl.backupAddress, checkoutProcessCtrl.addressDetails);

                if(selectedAddress.zipCode){
                    onZipCodeBlur();
                }
            }

            function openGoogleMapDialog() {
                var _defaultLocation;
                if(checkoutProcessCtrl.enableAddressFiltering){
                    switch (checkoutProcessCtrl.addressFiltering) {
                        case FILTER_ADDRESS_MODE.ZIP_CODE:{
                            _defaultLocation = checkoutProcessCtrl.addressDetails.zipCode;
                            break;
                        }
                        case FILTER_ADDRESS_MODE.CITY:{
                            _defaultLocation = checkoutProcessCtrl.addressDetails.city;
                            break;
                        }
                    }
                }

                if (window.cordova) {
                    return goToGoogleMapSelectPage(_defaultLocation);
                }

                mDesign.dialog({
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/google-map-dialog.html',
                    controller: 'GoogleMapDialogCtrl as googleMapDialogCtrl',
                    locals: {
                        defaultLocation: _defaultLocation
                    }
                }).then(_handleSelectedGoogleMapAddress);
            }

            function goToGoogleMapSelectPage(_defaultLocation){
              $state.go("app.cart.checkout.process.map", { from: STATE_NAME, defaultLocation: _defaultLocation });
            }

            function _isPreviousAddress(currAddr) {
                var prevAddr = checkoutProcessCtrl.previousFullDeliveryAddress.address;
                if(!prevAddr) return false;

                return _addressComponents.every(function (field) {
                    if(!prevAddr[field]) prevAddr[field] = '';
                    if(!currAddr[field]) currAddr[field] = '';
                    
                    return prevAddr[field].trim().toLowerCase() === currAddr[field].trim().toLowerCase();
                });
            }

            function _isLocationChanged(field) {
                if (!checkoutAddressCtrl.backupAddress || !checkoutAddressCtrl.backupAddress[field] ||  !checkoutProcessCtrl.addressDetails[field]) {
                    return true;
                }
                return checkoutAddressCtrl.backupAddress[field].trim().toLowerCase() !== checkoutProcessCtrl.addressDetails[field].trim().toLowerCase();
            }

            function _handleOnLocationBlur(event, emittedField) {
                // The externalPlaceId is used in setDeliveryTimes function, it's used to fetch all delivery time slots
                var isFieldChanged = _isLocationChanged(emittedField);
                if (isFieldChanged) {
                    angular.extend(checkoutProcessCtrl.addressDetails, {
                        externalPlaceId: null,
                        lat: null,
                        lng: null,
                        hasHouseNumberAndRoute: true,
                        isShowEditAddress: false,
                        isDisabledAddressField: false,
                    });
                    angular.extend(checkoutAddressCtrl.backupAddress, checkoutProcessCtrl.addressDetails);
                }
                // Compare to previous address, if they are match, we will set coordinates and externalPlaceId
                var isPrevAddr = _isPreviousAddress(checkoutProcessCtrl.addressDetails);
                if (isPrevAddr) {
                    var _curentAddress = checkoutProcessCtrl.addressDetails;
                    var _hasCoordinatesOrPlaceId = _curentAddress.lat && _curentAddress.lng || _curentAddress.externalPlaceId;
                    if(checkoutProcessCtrl.isAddressVerificationEnabled && _hasCoordinatesOrPlaceId) return;

                    var externalPlaceId = checkoutProcessCtrl.previousFullDeliveryAddress.address.externalPlaceId;
                    angular.extend(checkoutProcessCtrl.addressDetails, {
                        externalPlaceId: externalPlaceId,
                        lat: checkoutProcessCtrl.previousFullDeliveryAddress.address.lat,
                        lng: checkoutProcessCtrl.previousFullDeliveryAddress.address.lng,
                    });
                }
            }

            function onCountryBlur() {
                $timeout(function () {
                    $rootScope.$emit('checkout.details.location.blur', 'country');
                }, 300);
            }

            /**
             * Handle with existing text1
             * @param {string} text1 
             */
            function _handleWithExistingText1(text1) {
                if (!checkoutProcessCtrl.isAddressVerificationEnabled) {
                    return;
                }
                if (!checkoutProcessCtrl.addressDetails.hasHouseNumberAndRoute) {
                    checkoutProcessCtrl.addressDetails.isDisabledAddressField = true;
                    return;
                }

                $rootScope.$emit('checkout.details.location.blur', 'text1');

                var city = extractAddressField('city');
                if (city) {
                    // Validate text1
                    var address = text1 + ', ' + city;
                    var isExisted = isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, checkoutProcessCtrl.suggestedAddresses);
                    if (!isExisted) {
                        $rootScope.$emit('checkout.address.delivery.setFormError', 'text1');
                    }
                } else {
                    // Auto fill city from text1
                    var suggestion = checkoutProcessCtrl.suggestedAddresses.find(function (item) {
                        return item.mainValue && item.mainValue.toLowerCase() === text1.toLowerCase();
                    });
                    if (suggestion) {
                        onAddressChosen({ option: suggestion });
                    } else {
                        $rootScope.$emit('checkout.address.delivery.setFormError', 'text1');
                    }
                }
            }

            function _handleWithEmptyText1() {
                if (!checkoutProcessCtrl.addressDetails.hasHouseNumberAndRoute && $scope.addressForm['addressText']) {
                    $scope.addressForm['addressText'].$setValidity('required', false);
                    checkoutAddressCtrl.showErrors = true;
                }
            }

            /**
             * Set value for address field
             * @param {string} field 
             * @param {any} value 
             * @param {any} initialValue 
             */
            function _setOrInitValueForAddressField(field, value, initialValue) {
                var isDefined = angular.isDefined(checkoutProcessCtrl.addressDetails[field]);
                checkoutProcessCtrl.addressDetails[field] = isDefined ? value : initialValue;
            }

            function _isClickToSuggestion(event){
                // if click to choose suggestion, disable blur event temporarily
                var _parentTargetClickId = event && event.relatedTarget && event.relatedTarget.parentElement ? event.relatedTarget.parentElement.id : null;
                if (_parentTargetClickId && _parentTargetClickId.startsWith('sp_autocomplete_')) {
                    return true;
                }
                return false;
            }

            /**
             * Edit unrecognized address
             */
            function editUnrecognizedAddress() {
                if (!checkoutProcessCtrl.addressDetails.isDisabledAddressField) {
                    return;
                }
                var text1InputRef = document.getElementById('address_input');
                if (text1InputRef) {
                    checkoutProcessCtrl.addressDetails.isDisabledAddressField = false;
                    $timeout(function () {
                        text1InputRef.focus();
                    }, 0);
                }
            }

            function getFieldErrorMsg(field){
                var _mgs = _translate('address_form_field_error_' + field);

                return _mgs;
            }

            function isJustDropedNotDetectedAddress(){
                // address just droped pin - not the address from previous order
                return checkoutProcessCtrl.isDropPinAddress && checkoutProcessCtrl.isDropPinAddressNotDetected;
            }

            function isShowAddressField(){
                var _isAddressFieldEnable = checkoutProcessCtrl.addressFormSettings.address && checkoutProcessCtrl.addressFormSettings.address.isEnabled;
                if(!_isAddressFieldEnable) return false;

                if(!checkoutProcessCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return true;

                return !isJustDropedNotDetectedAddress()
            }

            function isDisableAddressField(filteringMode){
                // only work when isAddressVerificationEnabled ON
                if(!checkoutProcessCtrl.isAddressVerificationEnabled) return false;
                
                // in CITY mode address field will disable when city field is empty
                if(filteringMode === FILTER_ADDRESS_MODE.CITY && checkoutProcessCtrl.isAddressFilteringCityMode && !checkoutProcessCtrl.addressDetails.city) return true;
                
                // in ZIP_CODE mode address field will disable when city field is empty
                if(filteringMode === FILTER_ADDRESS_MODE.ZIP_CODE && checkoutProcessCtrl.isAddressFilteringZipCodeMode && !checkoutProcessCtrl.addressDetails.zipCode) return true;

                return false;
            }

            function isShowStreetAndHouseNumberFields(){
                if(!checkoutProcessCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return false;

                // DROP PIN
                return isJustDropedNotDetectedAddress();
            }

            function editDroppinAddressField(elementSelector, field){
                var _element = document.querySelector(elementSelector);
                if (_element) {
                    checkoutProcessCtrl.droppinDisableFields[field] = false;
                    $timeout(function () {
                        _element.focus();
                    }, 0);
                }
            }

            function onDroppinAddressFieldBlur(){
                checkoutProcessCtrl.droppinDisableFields.city = true;
                checkoutProcessCtrl.droppinDisableFields.text1 = true;
                checkoutProcessCtrl.droppinDisableFields.zipCode = true;
                checkoutProcessCtrl.droppinDisableFields.state = true;
            }

            // TODO clear event setFormError, hideFormError after pilot done
            _listeners.push($rootScope.$on('checkout.address.delivery.setFormError', _setFormErrorEvent));
            _listeners.push($rootScope.$on('checkout.address.delivery.hideFormError', _hideFormErrorEvent));
            _listeners.push($rootScope.$on('checkout.address.delivery.blurInput', _triggerInputBlur));
            _listeners.push($rootScope.$on('checkout.details.location.blur', _handleOnLocationBlur));
        }]);
})(angular);
